import _ from 'lodash';

const initialState = {
  VisiblePopupConversations: [],
  SelectedConversationId: null,
};

function MessagePopupReducer(state = initialState, action) {
  let newState;
  let conversationIdIndex;

  switch (action.type) {
    case 'SET_VISIBLE_MESSAGE_POPUPS':
      newState = _.cloneDeep(state);

      conversationIdIndex = newState.VisiblePopupConversations.findIndex(
        element =>
          action.payload.conversationId === element.conversationId && action.payload.messageType === element.messageType
      );
      if (action.payload.isVisible) {
        if (conversationIdIndex === -1) {
          newState.VisiblePopupConversations.unshift({
            conversationId: action.payload.conversationId,
            messageType: action.payload.messageType,
            personId: action.payload.personId,
            personNumber: action.payload.personNumber,
          });
        }
      } else if (conversationIdIndex > -1) {
        newState.VisiblePopupConversations.splice(conversationIdIndex, 1);
      }
      newState.SelectedConversationId = action.payload.conversationId;
      return newState;
    default:
      return state;
  }
}

function getVisibleConversations(state) {
  return state.MessagePopupReducer.VisiblePopupConversations;
}

function getSelectedConversationId(state) {
  return state.MessagePopupReducer.SelectedConversationId;
}

function checkConversationPopupVisiblity(state, conversationId) {
  return !!state.MessagePopupReducer.VisiblePopupConversations.find(x => x.conversationId === conversationId);
}

export { MessagePopupReducer, getVisibleConversations, getSelectedConversationId, checkConversationPopupVisiblity };
