import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Radio, Form } from 'antd';
import EmailWorkflow from './EmailWorkflow';
import EmailAutomation from './EmailAutomation';
import SmtpNotConfiguredWarning from '../SmtpNotConfiguredWarning/SmtpNotConfiguredWarning';
import DropdownWithLabel from '../DropdownWithLabel/DropdownWithLabel';
import InfoIconWithTooltip from '../Common/InfoIconWithTooltip/InfoIconWithTooltip';
import styles from './EmailAutomationWorkflowWrapper.module.scss';
import * as WorkflowReducer from '../../Reducers/WorkflowReducer';
import * as WorkflowDripTemplatesActions from '../../Actions/WorkflowActions';
import * as MergeTagsReducer from '../../Reducers/MergeTagsReducer';
import message from '../../Containers/CandidateDrawer/messages';
import jobMessage from '../JobForm/JobMessages';

const mapStateToProps = state => ({
  workflowDripTemplatesById: WorkflowReducer.getWorkflowDripTemplates(state),
  workflowDripTemplatesCount: WorkflowReducer.getWorkflowDripTemplatesCount(state),
  currentWorkflowDripTemplateIds: WorkflowReducer.getCurrentWorkflowDripTemplateIds(state),
  mergeTags: MergeTagsReducer.getMergeTags(state),
});
const mapDispatchToProps = {
  searchWorkflowTemplates: WorkflowDripTemplatesActions.searchWorkflowTemplates,
};
const FormItem = Form.Item;
function EmailAutomationWorkflowWrapper(props) {
  const {
    form,
    consentComplianceInfo,
    selectedEmailTemplateFrom,
    checkSelectedEmailHasLinkedSMTP,
    getEmailPanelDropdownList,
    currentUserEmail,
    onEmailFromChange,
    emailTemplateIds,
    emailTemplatesById,
    emailTemplatesCount,
    selectedDripTemplate,
    fetchEmailTemplates,
    isPulse,
    selectedEmailTemplateId,
    selectedEmailTemplateName,
    setDefaultEmailTemplateValues,
    emailTemplates,
    onChangeEmailTemplate,
    emailTemplateContentVisibility,
    toggleEmailContentVisibility,
    selectedEmailTemplateSubject,
    selectedEmailPreHeader,
    ref,
    updateBodyContent,
    selectedEmailTemplateBody,
    selectedEmailTemplateSendTo,
    onEmailSendToChange,
    candidateDownloadFilterConfig,
    candidateCommunicationFilterConfig,
    sendEmailTo,
    isSendToOptionAllowed,
    isEmailAutomationNoteAllowed,
    cssClassname,
    templateSelectorClassname,
    workflowDripTemplatesById,
    workflowDripTemplatesCount,
    searchWorkflowTemplates,
    currentWorkflowDripTemplateIds,
    setDefinitionId,
    setSelctedWorkflowTemplate,
    setStartTime,
    setEndTime,
    getIsEndTimeValid,
    getIsCampaignEndTimeValid,
    setCampaignTriggeredEndTime,
    automationRadioSelected,
    definitionId,
    startTime,
    endTime,
    campaignTriggeredEndTime,
    onChangeAutomationRadioOption,
    setAutomationPanelCheckedStatus,
    featureToggleList,
    setWorkflowDefinitionName,
    mergeTags,
    candidateContext,
    version,
    openWorkflowinNewTab,
    campaignDrawerVisbility,
  } = props;

  React.useEffect(() => {
    setAutomationPanelCheckedStatus();
  }, []);
  const automationActiveRadio = form.getFieldValue('AutomationActiveRadio');
  const isWorkflowSectionVisible = automationActiveRadio === 'Workflow' && featureToggleList.Workflow.IsEnabled;
  return (
    <div>
      {!campaignDrawerVisbility ? (
        <p className={styles.emailAutomationDescription}>
          <FormattedMessage {...message.automatedEmailsToCandidatesLabel} />
        </p>
      ) : null}
      {selectedEmailTemplateFrom && !checkSelectedEmailHasLinkedSMTP(selectedEmailTemplateFrom) ? (
        <div className={styles.smtpNotConfiguredAlert}>
          <SmtpNotConfiguredWarning selectedEmailFrom={selectedEmailTemplateFrom} version={version} />
        </div>
      ) : null}
      <div id="auto-email-from-container" className={styles.autoEmailFromContainer}>
        <DropdownWithLabel
          label={<FormattedMessage {...jobMessage.fromLabel} />}
          cssClassname={cssClassname}
          optionValues={getEmailPanelDropdownList()}
          initialValue={selectedEmailTemplateFrom}
          currentUserEmail={currentUserEmail}
          placeholderValue="Select an email address (required)"
          onChange={onEmailFromChange}
        />
        <InfoIconWithTooltip tooltipTitle={<FormattedMessage {...jobMessage.defaultAutomationEmailIdLabel} />} />
      </div>
      <div className={styles.radioFields} style={{ display: featureToggleList.Workflow.IsEnabled ? 'block' : 'none' }}>
        <FormItem>
          {form.getFieldDecorator('AutomationActiveRadio', { initialValue: automationRadioSelected })(
            <Radio.Group onChange={onChangeAutomationRadioOption}>
              <Radio value="Email" key="Email">
                <span>
                  <FormattedMessage {...jobMessage.emailLabel} />
                </span>
              </Radio>
              {featureToggleList.Workflow.IsEnabled && !campaignDrawerVisbility ? (
                <Radio value="Workflow" key="Workflow">
                  <span>
                    <FormattedMessage {...jobMessage.workflowLabel} />
                  </span>
                </Radio>
              ) : null}
            </Radio.Group>
          )}
        </FormItem>
      </div>
      {candidateContext === 'segment' ? (
        <div className={styles.templateLabel}>
          <FormattedMessage {...message.segmentTemplateSelectionPrompt} />
        </div>
      ) : null}
      <div style={{ display: isWorkflowSectionVisible ? 'block' : 'none' }}>
        <EmailWorkflow
          workflowDripTemplatesById={workflowDripTemplatesById}
          workflowDripTemplatesCount={workflowDripTemplatesCount}
          searchWorkflowTemplates={searchWorkflowTemplates}
          currentWorkflowDripTemplateIds={currentWorkflowDripTemplateIds}
          form={form}
          setSelctedWorkflowTemplate={setSelctedWorkflowTemplate}
          setDefinitionId={setDefinitionId}
          setEndTime={setEndTime}
          setStartTime={setStartTime}
          setCampaignTriggeredEndTime={setCampaignTriggeredEndTime}
          isSendToOptionAllowed={isSendToOptionAllowed}
          isEmailAutomationNoteAllowed={isEmailAutomationNoteAllowed}
          candidateDownloadFilterConfig={candidateDownloadFilterConfig}
          candidateCommunicationFilterConfig={candidateCommunicationFilterConfig}
          selectedEmailTemplateSendTo={selectedEmailTemplateSendTo}
          onEmailSendToChange={onEmailSendToChange}
          sendEmailTo={sendEmailTo}
          getIsEndTimeValid={getIsEndTimeValid}
          getIsCampaignEndTimeValid={getIsCampaignEndTimeValid}
          definitionId={definitionId}
          startTime={startTime}
          endTime={endTime}
          campaignTriggeredEndTime={campaignTriggeredEndTime}
          setWorkflowDefinitionName={setWorkflowDefinitionName}
          version={version}
          openWorkflowinNewTab={openWorkflowinNewTab}
          featureToggleList={featureToggleList}
          candidateContext={candidateContext}
        />
      </div>
      <div style={{ display: !isWorkflowSectionVisible ? 'block' : 'none' }}>
        <EmailAutomation
          consentComplianceInfo={consentComplianceInfo}
          emailTemplateIds={emailTemplateIds}
          emailTemplatesById={emailTemplatesById}
          emailTemplatesCount={emailTemplatesCount}
          selectedDripTemplate={selectedDripTemplate}
          fetchEmailTemplates={fetchEmailTemplates}
          isPulse={isPulse}
          selectedEmailTemplateId={selectedEmailTemplateId}
          selectedEmailTemplateName={selectedEmailTemplateName}
          setDefaultEmailTemplateValues={setDefaultEmailTemplateValues}
          emailTemplates={emailTemplates}
          onChangeEmailTemplate={onChangeEmailTemplate}
          emailTemplateContentVisibility={emailTemplateContentVisibility}
          toggleEmailContentVisibility={toggleEmailContentVisibility}
          selectedEmailTemplateSubject={selectedEmailTemplateSubject}
          selectedEmailPreHeader={selectedEmailPreHeader}
          ref={ref}
          updateBodyContent={updateBodyContent}
          selectedEmailTemplateBody={selectedEmailTemplateBody}
          selectedEmailTemplateSendTo={selectedEmailTemplateSendTo}
          onEmailSendToChange={onEmailSendToChange}
          candidateDownloadFilterConfig={candidateDownloadFilterConfig}
          candidateCommunicationFilterConfig={candidateCommunicationFilterConfig}
          sendEmailTo={sendEmailTo}
          isSendToOptionAllowed={isSendToOptionAllowed}
          isEmailAutomationNoteAllowed={isEmailAutomationNoteAllowed}
          templateSelectorClassname={templateSelectorClassname}
          mergeTags={mergeTags}
          candidateContext={candidateContext}
          featureToggleList={featureToggleList}
        />
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(EmailAutomationWorkflowWrapper));
