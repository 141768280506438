import React from 'react';
import { Form } from 'antd';
import { connect } from 'react-redux';
import CandidateRejectCard from '../../Components/CandidateRejectCard/CandidateRejectCard';
import * as manualSearchActions from '../../Actions/ManualSearchActions';
import { getManualSearchCriteria } from '../../Reducers/ManualSearchReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import ManualSearchContext from '../ManualSearchV2/ManualSearchWrapper/ManualSearchContext';
import {
  generateManualSearchPayload,
  generateManualSearchPayloadWithoutPersonalInfo,
  getInitialSources,
} from '../../Utils/ManualSearchUtils';
import { generateMustsBooleanString } from '../../Utils/BooleanStringUtility';
import { generateMandatorySkillsArray } from '../../Utils/CandidateRejectReasonsUtils';
import { getConfiguredSources, getConfig, getPortalsConfig } from '../../Reducers/ConfigReducer';
import { getSourceName } from '../../Utils/SourceUtils';
import { getJobDetails } from '../../Reducers/JobReducer';
import { getFilteredSources } from '../ManualSearchV2/ManualSearchForm/ManualSearchForm';

export const filterUniqueSkills = combinedMustHaves => {
  const uniqueSkillsSet = new Set();
  const filteredCombinedMustHaves = combinedMustHaves;
  Object.keys(filteredCombinedMustHaves).forEach(key => {
    const skills = filteredCombinedMustHaves[key].selectedSkills.join('-');
    if (uniqueSkillsSet.has(skills) || skills.length === 0) {
      delete filteredCombinedMustHaves[key];
    } else {
      uniqueSkillsSet.add(skills);
    }
  });

  return filteredCombinedMustHaves;
};

function CandidateRejectCardContainer(props) {
  const {
    openInNewTab,
    featureToggleList,
    unsavedCriteria = {},
    sources,
    currentJobDetails = {},
    userConfig = {},
    portalsConfig = {},
    candidateContext,
  } = props;
  const { form: manualSearchForm } = React.useContext(ManualSearchContext);

  const onAddingMissingMandatorySkills = selectedMandatorySkills => {
    const manualSearchFormValues = manualSearchForm.getFieldsValue();
    const { MustHaves: mustHaves } = manualSearchFormValues;
    const combinedMustHaves = { ...mustHaves, ...selectedMandatorySkills };
    const filteredCombinedMustHaves = filterUniqueSkills(combinedMustHaves);
    manualSearchForm.setFieldsValue({
      MustHaves: filteredCombinedMustHaves,
    });
  };

  const extractSelectedSkillsFromFormValues = form => {
    const formValues = form.getFieldsValue();
    const missingMandatorySkillsFormValues = formValues.MISSING_MANDATORY_SKILLS || {};
    const selectedMandatorySkills = {};
    Object.keys(missingMandatorySkillsFormValues).forEach(key => {
      selectedMandatorySkills[key] = {
        selectedSkills: missingMandatorySkillsFormValues[key].selectedSkills,
      };
    });
    return selectedMandatorySkills;
  };

  const getWhenNewTabManualSearchPayload = () => {
    const { form } = props;
    const selectedMandatorySkills = extractSelectedSkillsFromFormValues(form);
    const musts = generateMustsBooleanString(generateMandatorySkillsArray(selectedMandatorySkills));
    if (musts.length) {
      unsavedCriteria.Skills.Musts += ` AND ${musts}`;
      let mustsInUnsavedCriteria = unsavedCriteria.Skills.Musts;
      mustsInUnsavedCriteria = mustsInUnsavedCriteria.replace(/undefined\s*AND/g, '');
      unsavedCriteria.Skills.Musts = mustsInUnsavedCriteria;
    }
    return unsavedCriteria;
  };

  const handleManualSearchCriteria = selectedMandatorySkills => {
    const { jobId, updateManualSearchCriteria, saveManualSearchCriteria, setManualSearchFormValues } = props;
    const manualSearchFormValues = manualSearchForm.getFieldsValue();
    const { MustHaves: mustHaves } = manualSearchFormValues;
    const combinedMustHaves = { ...mustHaves, ...selectedMandatorySkills };
    manualSearchFormValues.MustHaves = combinedMustHaves;
    const unsavedCriteriaId = unsavedCriteria.Id;
    const isAdvancedSearchV2Enabled = featureToggleList.AdvanceSearchV2.IsEnabled;

    const manualSearchPayload = !openInNewTab
      ? generateManualSearchPayload({
          formValues: manualSearchFormValues,
          locationType: manualSearchFormValues.LocationType,
          activeSearchString: manualSearchFormValues.ActiveSearchString,
          id: unsavedCriteriaId,
          isAdvancedSearchV2Enabled,
        })
      : getWhenNewTabManualSearchPayload();
    const criteria = {
      JobId: jobId,
      IsDraft: true,
      Criteria: { ...manualSearchPayload },
    };
    if (!openInNewTab) {
      const isNonInternalPortalForSegmentEnabled = featureToggleList?.PrefetchAdvanceSearchResults?.IsEnabled;
      const _sources = getFilteredSources({
        candidateContext,
        isNonInternalPortalForSegmentEnabled,
        sources,
      });
      const sourceNamesSelected = manualSearchForm.getFieldValue('sourceNamesSelected') || [];
      const isAllPortalsAllowed = candidateContext === 'job' || isNonInternalPortalForSegmentEnabled;
      const defaultSources = unsavedCriteria.Sources || [];
      const _sourceNamesSelected = sourceNamesSelected?.length
        ? sourceNamesSelected
        : getInitialSources({
            jobCountryCode: currentJobDetails.CountryCode,
            userConfig,
            sources: defaultSources?.length && isAllPortalsAllowed ? defaultSources : _sources || [],
            portalsConfig,
            allowActiveChannel: true,
          });

      const selectedSources = (_sourceNamesSelected || [])
        .map(x => sources.find(y => getSourceName(y) === x))
        .filter(x => !!x);
      criteria.Criteria = { ...manualSearchPayload, Sources: selectedSources };
    }
    if (unsavedCriteriaId) {
      const criteriaWithoutPersonalInfo = generateManualSearchPayloadWithoutPersonalInfo({ criteria });
      updateManualSearchCriteria({ criteria: criteriaWithoutPersonalInfo, id: unsavedCriteriaId });
    } else {
      saveManualSearchCriteria({ criteria });
    }
    setManualSearchFormValues({
      manualSearchFormValues,
      jobId,
    });
  };

  const onCandidateRejectClick = () => {
    const { form } = props;
    const selectedMandatorySkills = extractSelectedSkillsFromFormValues(form);
    handleManualSearchCriteria(selectedMandatorySkills);
  };

  return (
    <CandidateRejectCard
      onCandidateRejectClick={onCandidateRejectClick}
      {...props}
      onAddingMissingMandatorySkills={onAddingMissingMandatorySkills}
      manualSearchForm={manualSearchForm}
    />
  );
}

const mapStateToProps = (state, props) => ({
  unsavedCriteria: getManualSearchCriteria(state, { jobId: props.jobId, criteriaType: 'Unsaved' }),
  featureToggleList: getFeatureToggleList(state),
  sources: getConfiguredSources(state),
  currentJobDetails: getJobDetails(state, props.jobId),
  userConfig: getConfig(state),
  portalsConfig: getPortalsConfig(state),
});

const mapDispatchToProps = {
  updateManualSearchCriteria: manualSearchActions.updateManualSearchCriteria,
  saveManualSearchCriteria: manualSearchActions.saveManualSearchCriteria,
  setManualSearchFormValues: manualSearchActions.setManualSearchFormValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(CandidateRejectCardContainer));
export { CandidateRejectCardContainer as CandidateRejectCardContainerWithoutStore };
