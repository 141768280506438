import React, { useMemo } from 'react';
import {  Input, Popover, Icon } from 'antd';
import { Grid } from 'react-virtualized';
import styles from './AllTabCandidateAggregationFilterDrawer.module.scss';
import LinkButton from '../../../Components/Common/LinkButton/LinkButton';
import { getGroupedKeywords } from '../../../Utils/CandidateListUtils';
import CheckboxItem from './CheckboxItem';

const { Search } = Input;

const Cell = React.memo(({ columnIndex, rowIndex, style, data }) => {
  const { isSearchPerformed } = data;
  const title = data.filteredTitles[rowIndex * data.columnCount + columnIndex];
  const groupedTitle = data.groupedTitlesList[rowIndex * data.columnCount + columnIndex];
  if (!isSearchPerformed && !groupedTitle) return null;
  if (isSearchPerformed && !title) return null;
  const { onChange, value: formValue } = data;

  const onChangeTitles = e => {
    const { checked, value } = e.target;
    if (checked) onChange([...formValue, value]);
    else onChange(formValue.filter(item => item !== value));
  };

  const onChangeGroupedTitles = e => {
    const { checked, value } = e.target;
    if (checked) onChange([...formValue, value, ...groupedTitle.childTitles]);
    else onChange(formValue.filter(item => !groupedTitle.childTitles.includes(item) && item !== value));
  };

  const someChildTitlesChecked = groupedTitle?.childTitles?.some(childTitle => formValue.includes(childTitle));

  const parentCheckboxIndeterminate = !formValue.includes(groupedTitle?.parentTitle) && someChildTitlesChecked;

  const childTitleItems = groupedTitle?.childTitles?.map(childTitle => (
    <CheckboxItem
      key={childTitle}
      value={childTitle}
      text={childTitle}
      count={data.sortedTitles[childTitle].CandidateIds?.length || 0}
      maxTextLength={15}
      onChange={onChangeTitles}
      checked={formValue.includes(childTitle)}
    />
  ));
  const childTitleItemsContainer = (
    <div
      style={{
        padding: '14px 17px 22px 10px',
        maxHeight: '250px',
        overflowY: 'scroll',
        maxWidth: '300px',
        gap: '5px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {childTitleItems}
    </div>
  );
  return (
    <div style={{ ...style, width: '100%', display: 'flex', alignItems: 'center', gap: '10px' }}>
      <CheckboxItem
        value={!isSearchPerformed ? groupedTitle.parentTitle : title}
        text={!isSearchPerformed ? groupedTitle.parentTitle : title}
        count={data.sortedTitles[!isSearchPerformed ? groupedTitle.parentTitle : title].CandidateIds?.length || 0}
        maxTextLength={15}
        onChange={!isSearchPerformed ? onChangeGroupedTitles : onChangeTitles}
        checked={formValue.includes(!isSearchPerformed ? groupedTitle.parentTitle : title)}
        indeterminate={!isSearchPerformed && parentCheckboxIndeterminate}
      />
      {!isSearchPerformed && childTitleItems?.length > 0 ? (
        <Popover
          content={childTitleItemsContainer}
          placement="bottom"
          mouseEnterDelay={0.5}
          mouseLeaveDelay={0.15}
          trigger={['hover', 'click']}
          destroyTooltipOnHide
          autoAdjustOverflow
          getPopupContainer={_ => document.body}
        >
          <Icon type="ordered-list" />
        </Popover>
      ) : null}
    </div>
  );
});

const TitlesFilter = ({
  sortedTitles,
  candidatesTitlesField,
  selectedTitles,
  onClickClear,
  form,
  filterRef,
  allTabAggregationFilter,
}) => {
  const [titleSearchTerm, setTitleSearchTerm] = React.useState('');

  const handleTitleSearchChange = e => {
    setTitleSearchTerm(e.target.value);
  };

  const isSearchPerformed = titleSearchTerm?.trim().length > 0 ?? false;

  const filteredTitles = Object.keys(sortedTitles || {}).filter(title =>
    title.toLowerCase().includes(titleSearchTerm.toLowerCase())
  );

  const groupedTitles = useMemo(() => {
    return getGroupedKeywords(allTabAggregationFilter.Titles);
  }, [allTabAggregationFilter.Titles]);

  const groupedTitlesList = useMemo(() => {
    return Object.entries(groupedTitles).map(([parentTitle, childTitles]) => ({
      parentTitle,
      childTitles,
    }));
  }, [groupedTitles]);
  const columnCount = 4;
  const columnWidth = 210;
  const rowHeight = 36;
  const rowCount = Math.ceil((isSearchPerformed ? filteredTitles.length : groupedTitlesList.length) / columnCount);

  const gridHeight = Math.min(rowHeight * rowCount, 200);
  const cellRenderer = ({ columnIndex, rowIndex, style }) => (
    <Cell
      columnIndex={columnIndex}
      rowIndex={rowIndex}
      style={style}
      data={{
        filteredTitles,
        groupedTitlesList,
        isSearchPerformed,
        sortedTitles,
        columnCount,
        onChange: form.getFieldProps(candidatesTitlesField).onChange,
        value: form.getFieldValue(candidatesTitlesField) || [],
      }}
    />
  );
  return (
    <div className={styles.filterSection}>
      <div className={styles.filterSectionHeader}>
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <span className={styles.subsectionTitle}>Job Titles</span>
          <Search
            className={styles.searchBox}
            placeholder="Search"
            allowClear
            value={titleSearchTerm}
            onChange={handleTitleSearchChange}
          />
        </div>
        <LinkButton
          buttonName="Clear all"
          onClickButton={() => {
            onClickClear(candidatesTitlesField);
          }}
          style={{ color: 'rgba(7, 16, 26, 0.5)' }}
        />
      </div>
      {form.getFieldDecorator(candidatesTitlesField, { initialValue: selectedTitles })(
        <Grid
          columnCount={columnCount}
          columnWidth={columnWidth}
          height={gridHeight}
          rowCount={rowCount}
          rowHeight={rowHeight}
          width={840}
          cellRenderer={cellRenderer}
          style={{ overflowX: 'hidden', maxHeight: '200px' }}
          ref={filterRef}
        />
      )}
    </div>
  );
};

export default TitlesFilter;
