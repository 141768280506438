import React from 'react';
import {  FormattedMessage } from 'react-intl';
import styles from './AssistedSourcingIndicator.module.scss';
import TickIcon from '../../Icons/TickIcon';
import messages from '../../Containers/CandidateFilters/CandidateFiltersMessage';

function AssistedSourcingIndicator(props) {
  return (
    <div className={styles.assistedSourcingIndicator}>
      <TickIcon />
      <div className={styles.assistedSourcingIndicatorText}><span><FormattedMessage {...messages.aryaSourceCandidateLabel} /></span></div>
    </div>
  );
}
export default AssistedSourcingIndicator;
