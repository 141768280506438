import React from 'react';
import _ from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Modal, Button, Spin, Icon, Checkbox, Tooltip, Alert, Form } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import cleanSet from 'clean-set';
import './ActivationDialog.scss';
import JobPortal from '../../Components/JobPortal/JobPortal';
import UserAlerts from '../../Components/UserAlerts/UserAlerts';
import ReviewCommentsHeader from '../../Components/ReviewDialog/ReviewCommentsHeader';
import * as JobActions from '../../Actions/JobActions';
import { getConfig, getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import { getSourceName, getSourceDisplayName, getSourceListItemSource } from '../../Utils/SourceUtils';
import { getIsJobActivationAllowed, getJobLimitExceededAlertMessage } from '../../Utils/JobUtils';
import { isDisabledReactivationOptions } from '../../Utils/ActivationUtils';
import ReActivationAlert from './ReActivationAlert/ReActivationAlert';
import message from './messages';
import { getEventNameByFeatureType } from '../../Analytics/Job/JobEventUtils';
import jobMessage from '../../Components/JobForm/JobMessages';

let PersistRecommendationSourcesOptions = null;

let PersistRecommendedStatusOptions = null;

const mapStateToProps = state => {
  return {
    sources: _.get(getConfig(state), ['PortalSources'], []),
    showVaultName: _.get(getConfig(state), ['ShowVaultName'], false),
    authorizedPortals: _.get(getConfig(state), ['AuthorizedPortals'], []),
    whiteLabelInfo: getWhiteLabelInformation(state),
    userConfig: getConfig(state),
  };
};

const mapDispatchToProps = {
  activatePortals: JobActions.activatePortals,
  fetchJobSourcingStats: JobActions.fetchJobSourcingStats,
  setActivationStateChanged: JobActions.setActivationStateChanged,
  fetchOrgActivatedJobsCount: JobActions.fetchOrgActivatedJobsCount,
  fetchOrgOpenedJobsCount: JobActions.fetchOrgOpenedJobsCount,
};

export const getIsButtonDisabled = (
  isActivationRequired,
  isAppliedCandidateScoringRequired,
  portalStatusChangeCount,
  aryaVersion
) => {
  if (aryaVersion === 'Lite') {
    return !(isActivationRequired || isAppliedCandidateScoringRequired);
  }
  if (isActivationRequired && isAppliedCandidateScoringRequired) {
    return !portalStatusChangeCount;
  }
  if (!isActivationRequired && isAppliedCandidateScoringRequired) {
    return false;
  }
  if (isActivationRequired && !isAppliedCandidateScoringRequired) {
    return !portalStatusChangeCount;
  }
  return true;
};

class ActivationDialogContainer extends React.Component {
  constructor(props) {
    super(props);
    const { intl } = this.props;
    PersistRecommendationSourcesOptions = {
      ARYA_BOOSTED: {
        id: 'ARYA_BOOSTED',
        label: intl.formatMessage({ ...jobMessage.aryaSourceLabel }),
        value: 'AssistedSourcing',
        isSelected: false,
      },
    };

    PersistRecommendedStatusOptions = {
      ARYA_RANKED: {
        id: 'ARYA_RANKED',
        label: intl.formatMessage({ ...jobMessage.aryaRankedLabel }),
        value: 'Sourced',
        isSelected: false,
      },
      SHORTLISTED: {
        id: 'SHORTLISTED',
        label: intl.formatMessage({ ...jobMessage.shortlistedLabel }),
        value: 'Shortlisted',
        isSelected: true,
      },
    };
    this.state = {
      portalStatusChangeCount: 0,
      expandReviewDetails: false,
      persistRecommendedStatusesState: {
        ...PersistRecommendedStatusOptions,
      },
      persistRecommendationSourcesState: {
        ...PersistRecommendationSourcesOptions,
      },
      derivedInitialPortalSelectionState: false,
      isActivationRequired: true,
      isAppliedCandidateScoringRequired: true,
    };
    this.handlePortalSelect = this.handlePortalSelect.bind(this);
    this.deactivateArya = this.deactivateArya.bind(this);
    this.handleActivation = this.handleActivation.bind(this);
    this.getPortalSourcesView = this.getPortalSourcesView.bind(this);
    this.onClickSeeReviewDetails = this.onClickSeeReviewDetails.bind(this);
    this.onPersistRecommendationSourcesChange = this.onPersistRecommendationSourcesChange.bind(this);
    this.onPersistRecommendedStatusesChange = this.onPersistRecommendedStatusesChange.bind(this);
    this.resetActivationDialogState = this.resetActivationDialogState.bind(this);
    this.getRetainedOptionsFromTheState = this.getRetainedOptionsFromTheState.bind(this);
    this.getAlertMessageContent = this.getAlertMessageContent.bind(this);
    this.getUnretainedOptionLabelsFromTheState = this.getUnretainedOptionLabelsFromTheState.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { job } = props;
    if (state.derivedInitialPortalSelectionState) {
      return null;
    }
    let jobActivationStatus = _.get(job, ['aryaState']);
    if (!jobActivationStatus) {
      return {
        jobActivationStatus: {},
        portalStatusChangeCount: 0,
      };
    }
    const hasAnyActiveSource = Object.values(jobActivationStatus).some(sourceActivationStatus => {
      const sourceName = getSourceName(sourceActivationStatus.Source, false).toLowerCase();
      return _.get(jobActivationStatus, [sourceName, 'IsActivated'], false);
    });
    const areDefaultConfiguredSourcesInactive = !hasAnyActiveSource && !job.lastChangeTime;
    let portalStatusChangeCount = 0;
    if (areDefaultConfiguredSourcesInactive) {
      portalStatusChangeCount = Object.values(jobActivationStatus)?.length;
    }

    Object.values(jobActivationStatus).forEach(sourceActivationStatus => {
      const sourceName = getSourceName(sourceActivationStatus.Source, false).toLowerCase();
      jobActivationStatus = cleanSet(
        jobActivationStatus,
        [sourceName, 'isClicked'],
        jobActivationStatus[sourceName].IsActivated || areDefaultConfiguredSourcesInactive
      );
    });
    return {
      jobActivationStatus,
      portalStatusChangeCount,
      derivedInitialPortalSelectionState: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { jobId, job, fetchJobSourcingStats, visible, fetchOrgActivatedJobsCount, fetchOrgOpenedJobsCount } =
      this.props;
    const isActivationStateChanged =
      _.get(job, ['activationStateChanged']) !== _.get(prevProps.job, ['activationStateChanged']) &&
      _.get(job, ['activationStateChanged'], false) === true;
    if (isActivationStateChanged) {
      fetchJobSourcingStats([jobId]);
    }
    if (!job.HadActivated && !prevProps.visible && visible) {
      fetchOrgActivatedJobsCount();
      fetchOrgOpenedJobsCount();
    }
  }

  onPersistRecommendedStatusesChange(event) {
    const { name, checked } = event.target;
    this.setState(prevState => ({
      persistRecommendedStatusesState: {
        ...prevState.persistRecommendedStatusesState,
        [name]: {
          ...prevState.persistRecommendedStatusesState[name],
          isSelected: checked,
        },
      },
    }));
  }

  onPersistRecommendationSourcesChange(event) {
    const { name, checked } = event.target;
    this.setState(prevState => ({
      persistRecommendationSourcesState: {
        ...prevState.persistRecommendationSourcesState,
        [name]: {
          ...prevState.persistRecommendationSourcesState[name],
          isSelected: checked,
        },
      },
    }));
  }

  onClickSeeReviewDetails() {
    const { expandReviewDetails } = this.state;
    this.setState({
      expandReviewDetails: !expandReviewDetails,
    });
  }

  getPortalSourcesView() {
    const {
      sources,
      authorizedPortals,
      job,
      showVaultName,
      aryaVersion,
      version,
      whiteLabelInfo,
      userConfig,
      activationDialogAdditionalHeader,
      featureToggleList,
    } = this.props;
    const { jobActivationStatus: currentJobActivationStatus, isActivationRequired } = this.state;
    const disableFlag = isDisabledReactivationOptions(job, version);
    const isJobActivationAllowed = getIsJobActivationAllowed(userConfig, job.HadActivated, job.HadOpened);
    const protalSourcesViewClass = classNames({
      'activate-portal-details-disable': !isActivationRequired,
      'activate-portal-details': isActivationRequired,
    });
    const isAssistedSourcingEnabled = _.get(featureToggleList, ['AssistedSourcing', 'IsEnabled'], false);
    const isAssistedCandidateIndicatorEnabled = _.get(
      featureToggleList,
      ['AssistedCandidateIndicator', 'IsEnabled'],
      false
    );

    const isBoostedCheckboxVisible = isAssistedSourcingEnabled && isAssistedCandidateIndicatorEnabled;
    const shouldHideAlert =
      disableFlag ||
      !isActivationRequired ||
      (this.state.persistRecommendedStatusesState[PersistRecommendedStatusOptions.ARYA_RANKED.id].isSelected === true &&
        this.state.persistRecommendedStatusesState[PersistRecommendedStatusOptions.SHORTLISTED.id].isSelected ===
          true &&
        (!isBoostedCheckboxVisible ||
          this.state.persistRecommendationSourcesState[PersistRecommendationSourcesOptions.ARYA_BOOSTED.id]
            .isSelected === true));


    return (
      <div className={protalSourcesViewClass}>
        {activationDialogAdditionalHeader}
        <div style={{ paddingBottom: '8px' }}>
          <div className="persist-candidate-type-question">
            <FormattedMessage {...message.persistCandidatesTypeQuestionMessage} />
          </div>
          <Checkbox
            name={PersistRecommendedStatusOptions.ARYA_RANKED.id}
            className="persist-candidate-type-options"
            checked={
              this.state.persistRecommendedStatusesState[PersistRecommendedStatusOptions.ARYA_RANKED.id].isSelected
            }
            disabled={disableFlag}
            onChange={this.onPersistRecommendedStatusesChange}
          >
            {PersistRecommendedStatusOptions.ARYA_RANKED.label}
          </Checkbox>
          <Checkbox
            name={PersistRecommendedStatusOptions.SHORTLISTED.id}
            className="persist-candidate-type-options"
            checked={
              this.state.persistRecommendedStatusesState[PersistRecommendedStatusOptions.SHORTLISTED.id].isSelected
            }
            disabled={disableFlag}
            onChange={this.onPersistRecommendedStatusesChange}
          >
            {PersistRecommendedStatusOptions.SHORTLISTED.label}
          </Checkbox>
          {isBoostedCheckboxVisible && (
            <Checkbox
              name={PersistRecommendationSourcesOptions.ARYA_BOOSTED.id}
              className="persist-candidate-type-options"
              checked={
                this.state.persistRecommendationSourcesState[PersistRecommendationSourcesOptions.ARYA_BOOSTED.id]
                  .isSelected
              }
              onChange={this.onPersistRecommendationSourcesChange}
              disabled={disableFlag}
            >
              {PersistRecommendationSourcesOptions.ARYA_BOOSTED.label}
            </Checkbox>
          )}
        </div>
        {!shouldHideAlert && (
          <Alert type="warning" message={this.getAlertMessageContent()} showIcon className="warning-message" />
        )}
        {aryaVersion !== 'Lite' ? (
          <div className="portals">
            <div className="source-title">
              <FormattedMessage {...message.chooseSource} />
            </div>
            <div className="portal-activation-buttons">
              {sources.map(portalSource => {
                const sourceName = getSourceName(portalSource);
                const sourceDisplayName = getSourceDisplayName(
                  portalSource,
                  userConfig,
                  showVaultName,
                  {
                    Name: 'activationDialougeIcon',
                  },
                  whiteLabelInfo
                );
                const index = sourceName.toLowerCase();
                const isDisabled =
                  !authorizedPortals.includes(portalSource.Group || portalSource.Portal) ||
                  !_.get(currentJobActivationStatus, [index], false);
                return (
                  <JobPortal
                    name={sourceDisplayName}
                    showVaultName={showVaultName}
                    key={sourceName}
                    index={index}
                    source={portalSource}
                    selected={
                      !isDisabled &&
                      currentJobActivationStatus &&
                      currentJobActivationStatus[index] &&
                      currentJobActivationStatus[index].isClicked
                    }
                    change={this.handlePortalSelect}
                    disabled={!isJobActivationAllowed || isDisabled}
                  />
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  handlePortalSelect(sourceName) {
    const { jobActivationStatus } = this.state;
    let { portalStatusChangeCount } = this.state;
    jobActivationStatus[sourceName].isClicked = !jobActivationStatus[sourceName].isClicked;
    if (jobActivationStatus[sourceName].isClicked === jobActivationStatus[sourceName].IsActivated) {
      portalStatusChangeCount -= 1;
    } else {
      portalStatusChangeCount += 1;
    }
    this.setState({
      jobActivationStatus,
      portalStatusChangeCount,
    });
  }

  resetActivationDialogState = () => {
    this.setState({
      persistRecommendedStatusesState: {
        ...PersistRecommendedStatusOptions,
      },
      persistRecommendationSourcesState: {
        ...PersistRecommendationSourcesOptions,
      },
    });
  };

  handleScoreAndRankCheckboxClicked = value => {
    const { checked } = value.target;
    this.setState({
      isActivationRequired: checked,
    });
  };

  handleScoreAndRankAppliedCheckboxClicked = value => {
    this.setState({
      isAppliedCandidateScoringRequired: value.target.checked,
    });
  };

  getRetainedOptionsFromTheState = state => {
    return Object.keys(state)
      .filter(key => state?.[key].isSelected === true)
      .map(key => state[key].value);
  };

  getUnretainedOptionLabelsFromTheState = state => {
    return Object.keys(state)
      .filter(key => state?.[key].isSelected === false)
      .map(key => state[key].label);
  };

  handleActivation() {
    const {
      jobId,
      activatePortals,
      handleCancel,
      onStatusChange,
      activeTab,
      sources,
      aryaVersion,
      storeJobReactivationApiStatus,
      searchCriteria,
    } = this.props;
    const {
      jobActivationStatus,
      isActivationRequired,
      isAppliedCandidateScoringRequired,
      persistRecommendationSourcesState,
      persistRecommendedStatusesState,
    } = this.state;
    const sourceList = [];
    const persistRecommendationSources = this.getRetainedOptionsFromTheState(persistRecommendationSourcesState);
    const persistRecommendedStatuses = this.getRetainedOptionsFromTheState(persistRecommendedStatusesState);
    if (aryaVersion === 'Lite') {
      sources.forEach(source => {
        sourceList.push({
          Source: { Portal: source.Portal, Name: source.Name, Type: source.Type },
          PersistRecommendedStatuses: ['Rejected', ...persistRecommendedStatuses],
          PersistRecommendationSources: persistRecommendationSources,
          IsActivated: true,
        });
      });
    } else {
      sources.forEach(source => {
        const sourceName = getSourceName(source);
        const index = sourceName.toLowerCase();
        const sourceListItemSource = getSourceListItemSource(source);
        const sourceListItem = {
          PersistRecommendedStatuses: ['Rejected', ...persistRecommendedStatuses],
          PersistRecommendationSources: persistRecommendationSources,
          IsActivated: _.get(jobActivationStatus, [index, 'isClicked'], false),
          Source: sourceListItemSource,
        };
        sourceList.push(sourceListItem);
      });
    }
    const activationDetails = {
      JobId: jobId,
      Sources: sourceList,
      IsActivationRequired: isActivationRequired,
      IsAppliedCandidateScoringRequired: isAppliedCandidateScoringRequired,
    };
    if (sourceList.length || aryaVersion !== 'Lite') {
      activatePortals(activationDetails, storeJobReactivationApiStatus, searchCriteria);
    }
    handleCancel({ isAryaReactivated: true });
    this.resetActivationDialogState();
    if (activeTab !== 'ranked') {
      onStatusChange('ranked');
    }
  }

  deactivateArya() {
    const { jobId, activatePortals, handleCancel, sources, storeJobReactivationApiStatus } = this.props;
    const deactivateSourceList = [];
    const persistRecommendationSources = this.getRetainedOptionsFromTheState(
      this.state.persistRecommendationSourcesState
    );
    const persistRecommendedStatuses = this.getRetainedOptionsFromTheState(this.state.persistRecommendedStatusesState);
    sources.forEach(source => {
      const sourceListItemSource = getSourceListItemSource(source);
      deactivateSourceList.push({
        Source: sourceListItemSource,
        IsActivated: false,
        PersistRecommendedStatuses: ['Rejected', ...persistRecommendedStatuses],
        PersistRecommendationSources: persistRecommendationSources,
      });
    });
    const deactivationDetails = {
      JobId: jobId,
      Sources: deactivateSourceList,
    };
    if (deactivateSourceList.length) {
      activatePortals(deactivationDetails, storeJobReactivationApiStatus);
    }
    handleCancel();
    this.resetActivationDialogState();
  }

  getAlertMessageContent() {
    const { intl, featureToggleList } = this.props;
    const unRetainedRecommendationSources = this.getUnretainedOptionLabelsFromTheState(
      this.state.persistRecommendationSourcesState
    );
    const unRetainedRecommendedStatuses = this.getUnretainedOptionLabelsFromTheState(
      this.state.persistRecommendedStatusesState
    );
    let unRetainedOptions = [...unRetainedRecommendedStatuses, ...unRetainedRecommendationSources];
    const isAssistedSourcingEnabled = _.get(featureToggleList, ['AssistedSourcing', 'IsEnabled'], false);
    const isAssistedCandidateIndicatorEnabled = _.get(featureToggleList, ['AssistedCandidateIndicator', 'IsEnabled'], false);
    const isAryaBoostCheckBoxVisible = isAssistedCandidateIndicatorEnabled && isAssistedSourcingEnabled;
    if (!isAryaBoostCheckBoxVisible) {
      unRetainedOptions = unRetainedOptions.filter(
        label => label !== PersistRecommendationSourcesOptions.ARYA_BOOSTED.label
      );
    }
    if (unRetainedOptions.length === 0) return '0';
    let alertMessage = '';

    if (unRetainedOptions.length === 1) {
      alertMessage += intl.formatMessage(
        { ...jobMessage.allUnRetainedOptionsCandidatesWillBeDeletedLabel },
        { unRetainedOptions: unRetainedOptions[0] }
      );
    } else {
      const lastOption = unRetainedOptions.pop();
      const optionsList = unRetainedOptions.join(', ');
      alertMessage += intl.formatMessage(
        { ...jobMessage.allUnRetainedOptionsAndLastOptionCandidatesWillBeDeletedLabel },
        { unRetainedOptions: optionsList, lastOption }
      );
    }

    return alertMessage;
  }

  render() {
    const {
      jobActivationStatus,
      jobId,
      visible,
      handleCancel,
      /* showReviewDetails , */ sources,
      job,
      aryaVersion,
      whiteLabelInfo,
      userConfig,
      activationDialogAdditionalHeader,
      closeButtonTooltip,
      type,
      featureToggleList,
      smartRecruiterVersion,
      version,
    } = this.props;
    const {
      jobActivationStatus: currentJobActivationStatus,
      portalStatusChangeCount,
      expandReviewDetails,
      retainId,
      isActivationRequired,
      isAppliedCandidateScoringRequired,
    } = this.state;

    const AppName = _.get(whiteLabelInfo, ['AppName'], 'Arya');

    let deactivateButtonName = <FormattedMessage {...message.deactivateArya} values={{ AppName }} />;
    let reactivateAryaButton = <FormattedMessage {...jobMessage.reactivateLabel} values={{ AppName }} />;
    if (featureToggleList?.AdvanceSearchV2?.IsEnabled || smartRecruiterVersion === 'v2') {
      deactivateButtonName = <FormattedMessage {...jobMessage.stopScoreAndRankLabel} />;
      reactivateAryaButton = <FormattedMessage {...jobMessage.scoreAndRankLabel} />;
    }
    const isJobActivationAllowed = getIsJobActivationAllowed(userConfig, job.HadActivated, job.HadOpened);
    const { recentSourcedTime, lastChangeTime } = job;
    const recentSourced = moment.utc(recentSourcedTime).local();
    const lastChange = moment.utc(lastChangeTime).local();
    let lastSourcedTime = lastChange;
    if (recentSourcedTime) {
      lastSourcedTime = moment.max(recentSourced, lastChange);
    }
    let reviewCommentState = false;
    const timeDiff = moment().diff(lastSourcedTime, 'days');
    if (timeDiff >= 2) {
      reviewCommentState = true;
    }
    if (
      _.get(job, 'sourcingStats.Shortlisted', 0) ||
      _.get(job, 'sourcingStats.Sourced', 0) ||
      _.get(job, 'sourcingStats.Connected', 0)
    ) {
      reviewCommentState = false;
    }
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
    let footer;

    if (
      !currentJobActivationStatus ||
      sources.every(source => {
        const sourceName = getSourceName(source).toLowerCase();
        return !_.get(currentJobActivationStatus, [sourceName, 'IsActivated'], false);
      })
    ) {
      footer = [
        <Button
          key="submit"
          type="primary"
          onClick={this.handleActivation}
          disabled={
            !isJobActivationAllowed ||
            getIsButtonDisabled(
              isActivationRequired,
              isAppliedCandidateScoringRequired,
              portalStatusChangeCount,
              aryaVersion
            )
          }
          className="activate-btn-text-jay"
        >
          {featureToggleList?.AdvanceSearchV2?.IsEnabled || smartRecruiterVersion === 'v2' ? (
            <FormattedMessage {...jobMessage.scoreAndRankLabel} />
          ) : (
            <FormattedMessage {...message.activateArya} values={{ AppName }} />
          )}
        </Button>,
      ];
    } else {
      footer = [
        <Button
          key="deactivate"
          type="danger"
          onClick={this.deactivateArya}
          className="deactivate"
          disabled={!isJobActivationAllowed}
          sk-event-name={getEventNameByFeatureType(type, 'deactivateArya')}
        >
          {deactivateButtonName}
        </Button>,
        <Button
          key="reactivate"
          type="primary"
          onClick={this.handleActivation}
          id="reactivate-arya-btn"
          disabled={!isJobActivationAllowed || !(isActivationRequired || isAppliedCandidateScoringRequired)}
          sk-event-name={getEventNameByFeatureType(type, 'reactivateArya')}
        >
          {reactivateAryaButton}
        </Button>,
      ];
    }

    const closeIcon = (
      <Tooltip placement="top" title={closeButtonTooltip}>
        <Icon type="close" />
      </Tooltip>
    );

    return (
      <>
        {!isJobActivationAllowed && visible ? (
          <div className="userAlertWrapper">
            <UserAlerts
              header={<FormattedMessage {...jobMessage.featureLockedLabel} />}
              content={getJobLimitExceededAlertMessage(userConfig.JobLimit)}
            />
          </div>
        ) : null}
        <ReActivationAlert retainId={retainId} />
        <Modal
          className="activate-modal-id"
          width="579px"
          visible={visible}
          wrapClassName="activation-modal-wrapper"
          title={
            !activationDialogAdditionalHeader ? (
              <span className="activate-modal-title">
                <FormattedMessage {...message.aryaSourcing} values={{ AppName }} />
                {reviewCommentState ? <FormattedMessage {...jobMessage.needsReviewLabel} /> : ''}
              </span>
            ) : null
          }
          onOk={this.handleActivation}
          onCancel={handleCancel}
          footer={!expandReviewDetails ? footer : null}
          closeIcon={closeIcon}
        >
          <Spin indicator={antIcon} spinning={!jobActivationStatus}>
            {reviewCommentState ? (
              <ReviewCommentsHeader
                jobId={jobId}
                isExpanded={expandReviewDetails}
                onClickSeeDetails={this.onClickSeeReviewDetails}
                AppName={AppName}
              />
            ) : null}
            <Checkbox className="score-rank" defaultChecked onChange={this.handleScoreAndRankCheckboxClicked}>
              <FormattedMessage {...message.aryaScoringRanking} />
            </Checkbox>
            <Checkbox
              className="score-rank-applied"
              defaultChecked={false}
              onChange={this.handleScoreAndRankAppliedCheckboxClicked}
              checked={isAppliedCandidateScoringRequired}
            >
              <FormattedMessage {...message.aryaScoringRankingApplied} />
            </Checkbox>
            {reviewCommentState && expandReviewDetails ? null : this.getPortalSourcesView()}
          </Spin>
        </Modal>
      </>
    );
  }
}

ActivationDialogContainer.propTypes = {
  jobId: PropTypes.number,
  jobActivationStatus: PropTypes.shape({}),
  visible: PropTypes.bool,
  fetchActivationStatus: PropTypes.func.isRequired,
  activatePortals: PropTypes.func.isRequired,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string,
      Type: PropTypes.string,
      Portal: PropTypes.string,
    })
  ),
  handleCancel: PropTypes.func,
};

ActivationDialogContainer.defaultProps = {
  jobActivationStatus: {},
  visible: false,
  sources: [],
  handleCancel: () => {},
  jobId: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(ActivationDialogContainer)));

export { ActivationDialogContainer as ActivationDialogContainerWithoutStoreAndInjectIntl };
