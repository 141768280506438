import React from 'react';
import { Empty, Skeleton, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { getPortalName } from '../../Utils/SourceUtils';
import FetchContactInfo from '../../Components/Connect/FetchContactInfo/FetchContactInfo';
import ConnectMessageWindow from '../../Components/Connect/ConnectMessageWindow/ConnectMessageWindow';
import {
  getMessageConversations,
  getMessageConversationOfAllPhoneNumbers,
  getPendingMessageConversation,
} from '../../Reducers/MessageConversationsReducer';
import {
  getCandidatesConnectInfo,
  getMessageTemplates,
  getConsentMessageTemplates,
  getNonConsentMessageTemplates,
  getOrgPhoneConsentInfo,
  getAllPhoneNumbersOfCandidate,
} from '../../Reducers/ConnectReducer';
import * as ConnectActions from '../../Actions/ConnectActions';
import * as AryaNotifyActions from '../../Actions/AryaNotifyActions';
import * as AryaPayActions from '../../Actions/AryaPayActions';
import * as CandidateActions from '../../Actions/CandidateActions';
import getMergeTags from '../../Actions/MergeTagsActions';
import { getTextTemplate as _getTextTemplate } from '../../Actions/TextTemplates';
import { getTextTemplatesById } from '../../Reducers/TextTemplates';
import * as MergeTagsReducer from '../../Reducers/MergeTagsReducer';
import * as AryaPayReducer from '../../Reducers/AryaPayReducer';
import { getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import config from '../../Config/Config';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getEmptyContactsTabScreenStyle, getNonRefundedPhones } from '../../Utils/ContactUtils';
import { getSelectedMessageTemplateDetails } from '../../Utils/MessageTemplateUtil';
import { getFilteredMergeTags } from '../../Utils/MergeTagsUtils';
import { TextIcon } from '../../Icons/AryaIcons';
import { getCommunicationDisabledContent, getIsCandidateCommunicationDisabled } from '../../Utils/ConnectUtils';
import { UserAlertWithWrapper } from '../../Components/UserAlerts/UserAlerts';
import Candidate360EmailAndMessageEmptyScreen from '../../Components/Connect/Candidate360ContactInfo/Candidate360EmailAndMessageEmptyScreen';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getCommunicationTabsErrorMessage } from '../../Utils/CandidateApiErrorResponseMapper';
import { readPhoneNumberSMS } from '../../Repository/ConnectRepository';
import styles from './MessageWindow.module.scss';
import {
  checkForJunkWordsInText,
  removeUnicodeQuotation,
  parseTextForJunkDetection,
  getInfoAlertMessage,
} from '../../Components/Utils/SmsUtils';
import { getUsersByGuId } from '../../Reducers/UserReducer';
import { fetchBasicJobsInfo as _fetchBasicJobsInfo } from '../../Actions/JobActions';
import { getJobsBasicInfoById, getBasicInfoJobGuidToIdMapping } from '../../Reducers/JobsBasicInfoReducer';
import message from '../CandidateDrawer/messages';
import { getAvailableCredits } from '../../Utils/CreditInfoUtils';
import { getVariantId } from '../../Utils/ProductVariantDetails';

const mapStateToProps = (state, ownProps) => {
  return {
    MessageConversations: getMessageConversations(state, ownProps.conversationId),
    CandidatesConnectInfo: getCandidatesConnectInfo(state),
    messageTemplates: getMessageTemplates(state),
    consentMessageTemplates: getConsentMessageTemplates(state),
    nonConsentMessageTemplates: getNonConsentMessageTemplates(state),
    mergeTags: MergeTagsReducer.getMergeTags(state),
    availableCredits: AryaPayReducer.getAvailableCredits(state),
    featureToggleList: getFeatureToggleList(state),
    whiteLabelInfo: getWhiteLabelInformation(state),
    TextTemplatesById: getTextTemplatesById(state),
    setConversationMessagesApiStatus: getApiStatus(state, 'SetConversationMessagesApiStatus'),
    getSendMessageApiStatus: getApiStatus(state, 'setFetchSendMessageApiStatus'),
    MessageConversationsofAllPhonenumbers: getMessageConversationOfAllPhoneNumbers(state, ownProps.conversationId),
    PendingMessageConversation: getPendingMessageConversation(state, ownProps.conversationId),
    usersByGuId: getUsersByGuId(state),
    basicInfoJobGuidToIdMapping: getBasicInfoJobGuidToIdMapping(state),
    jobsBasicInfoById: getJobsBasicInfoById(state),
    orgPhoneConsentInfo: getOrgPhoneConsentInfo(state, ownProps.candidate.PersonId),
    phoneNumbers: getAllPhoneNumbersOfCandidate(state, ownProps.candidate.PersonId),
  };
};

const mapDispatchToProps = {
  fetchMessages: ConnectActions.fetchMessages,
  fetchLatestMessages: ConnectActions.fetchLatestMessages,
  setMessageConversationApiStatus: ConnectActions.setMessageConversationApiStatus,
  sendMessage: CandidateActions.sendMessage,
  fetchConnectStatus: ConnectActions.fetchConnectStatus,
  fetchMessageTemplates: ConnectActions.fetchMessageTemplates,
  fetchMergeTags: getMergeTags,
  markConversationAsRead: ConnectActions.markConversationAsRead,
  markNotificationAsRead: AryaNotifyActions.markNotificationAsRead,
  fetchAvailableCredits: AryaPayActions.fetchAvailableCredits,
  getTextTemplate: _getTextTemplate,
  setInvalidTemplateNotification: ConnectActions.setInvalidTemplateNotification,
  addContact: ConnectActions.addContact,
  fetchBulkUnReadConversationsCount: ConnectActions.fetchBulkUnReadConversationsCount,
  fetchBasicJobsInfo: _fetchBasicJobsInfo,
};
let lastConversationWithCandidate = null;

const debouncedCheckForJunkWordsInText = _.debounce((plainTextContent, setter, junkWordsSetter) => {
  const { hasJunkWords, junkWords } = checkForJunkWordsInText(plainTextContent);
  setter(hasJunkWords);
  junkWordsSetter(junkWords);
}, 600);

class MessageWindowContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMessageInputContent: null,
      selectedPhone: undefined,
      isConsent: undefined,
      jobDetails: {},
      isJobDetailsFetched: false,
      isJunkWordFound: false,
      junkWords: [],
    };
    this.connectRef = React.createRef();
    this.onSendNewMessage = this.onSendNewMessage.bind(this);
    this.onUpdateMessageContent = this.onUpdateMessageContent.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onCloseMessageComposer = this.onCloseMessageComposer.bind(this);
    this.onTemplateSelect = this.onTemplateSelect.bind(this);
    this.markMessageAsRead = this.markMessageAsRead.bind(this);
  }

  static getDerivedStateFromProps(props, prevState) {
    const updatedState = {};
    const {
      MessageConversations = {},
      CandidatesConnectInfo,
      candidate,
      phoneNumber,
      MessageConversationsofAllPhonenumbers = {},
      PendingMessageConversation = {},
      fetchBasicJobsInfo,
      orgPhoneConsentInfo,
      phoneNumbers,
    } = props;

    let currentPhones;
    let { selectedPhone } = prevState;
    const { candidateId, contact: prevContact, isJobDetailsFetched } = prevState;
    const candidateConnectId = candidate.PersonId;
    const candidateConnectedInfo = _.get(CandidatesConnectInfo, ['ConnectStatuses', candidateConnectId], null);
    const contact = _.get(candidateConnectedInfo, 'Contact', {});
    const phones = _.get(contact, 'Phones', []);
    const {
      ConsentStatus: consentStatus,
      ConsentConfiguration: consentConfiguration,
      Subscription: subscription,
    } = contact;
    const lastConversationWith = _.get(candidateConnectedInfo, ['SmsStatus', 'LastConversationWith'], null);
    lastConversationWithCandidate = lastConversationWith;
    if (selectedPhone === undefined || candidateId !== candidate.Id) {
      if (lastConversationWith) {
        selectedPhone = { Number: lastConversationWith };
      } else if (phones.length) {
        const nonRefundedPhones = getNonRefundedPhones(phones);
        if (selectedPhone) {
          currentPhones = nonRefundedPhones.filter(phone => {
            return phone.Number === selectedPhone.Number;
          });
        }
        [selectedPhone] = (currentPhones || []).concat(nonRefundedPhones);
      } else selectedPhone = undefined;
    }

    if (selectedPhone) {
      const phone = phones.find(p => p.Number === selectedPhone.Number);
      selectedPhone = {
        ...selectedPhone,
        ...phone,
      };
      const messageConsentInfo = selectedPhone?.MessageConsentInfo;
      const PhoneNumber = selectedPhone.Number;
      const messageConsentInfoForSelectedPhone = orgPhoneConsentInfo?.[PhoneNumber];

      const refIds = phoneNumbers
        .filter(phone => orgPhoneConsentInfo?.[phone])
        .map(phone => orgPhoneConsentInfo[phone].RefId);
      if (refIds.length > 0 && !isJobDetailsFetched) {
        fetchBasicJobsInfo({
          jobGuids: refIds,
        });
        updatedState.isJobDetailsFetched = true;
      }
    }
    const isCommunicationAllowed =
      !(phones || []).some(phone => !phone.IsCommunicationAllowed) &&
      subscription?.Status?.toLowerCase() !== 'unsubscribed';
    const showFetchContact =
      !candidateConnectedInfo ||
      (!(getNonRefundedPhones(phones) || []).length &&
        (!consentStatus ||
          consentStatus === 'Approved' ||
          (consentConfiguration && consentConfiguration.IsContactViewAllowed)));

    const { Phones: prevPhones } = prevContact || {};
    if (prevPhones?.length !== phones.length && phoneNumber) {
      selectedPhone = (phones || []).find(p => p.Number === phoneNumber) || null;
    }

    updatedState.candidateId = candidate.Id;

    const messages = [];
    const allPhoneNumbers = phones.map(p => p.Number);
    allPhoneNumbers.forEach(number => {
      if (MessageConversationsofAllPhonenumbers[number]?.length > 0) {
        messages.push(MessageConversationsofAllPhonenumbers[number][0]);
      } else if (PendingMessageConversation[number]?.length > 0) {
        const pendingMessage = {
          ...PendingMessageConversation[number][0],
          pendingState: true,
        };
        messages.push(pendingMessage);
      }
    });
    updatedState.messages = messages;
    updatedState.showFetchContact = showFetchContact;
    updatedState.selectedPhone = selectedPhone;
    updatedState.isCommunicationAllowed = isCommunicationAllowed;
    updatedState.contact = contact;
    updatedState.messageConsentStatus = selectedPhone ? selectedPhone.MessageConsentStatus : undefined;

    return updatedState;
  }

  componentDidMount() {
    const {
      fetchLatestMessages,
      conversationId,
      fetchMessageTemplates,
      availableCredits,
      fetchAvailableCredits,
      candidate: { Id: userId },
    } = this.props;
    // fetches All templates and sets consent, non-consent
    // templates & message Templates
    fetchMessageTemplates(0, 100, { IncludeSystemTemplate: true });

    // fetch Consent templates
    // fetchMessageTemplates(0, 100, true);
    // fetch Non Consent templates
    // fetchMessageTemplates(0, 100, false);
    if (conversationId) fetchLatestMessages(conversationId, userId, 'Success', 'SMS');

    if (availableCredits == null) {
      fetchAvailableCredits(getVariantId('Arya'), 'Arya');
      fetchAvailableCredits(getVariantId('SMS'), 'SMS');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      conversationId,

      TextTemplatesById,
      candidate: { Id: userId },
      fetchLatestMessages,
      basicInfoJobGuidToIdMapping,
      jobsBasicInfoById,
      orgPhoneConsentInfo,
    } = this.props;
    const { /* messages, messageConsentStatus, */ templateId, selectedPhone, jobDetails } = this.state;

    if (prevProps.conversationId !== conversationId && conversationId) {
      fetchLatestMessages(conversationId, userId, 'Success', 'SMS');
    }

    // if ((!messageConsentStatus || messageConsentStatus === 'Pending') && messages && messages.length) {
    //   const latestMessage = messages[0];
    //   if (latestMessage.IsByBot || latestMessage.IsByPerson) {
    //     fetchConnectStatus(conversationId, candidate.PersonId);
    //   }
    // }

    if (templateId) {
      const selectedTemplateFromPrevProps = _.get(prevProps.TextTemplatesById, templateId, {});
      const selectedTemplateFromCurrentProps = _.get(TextTemplatesById, templateId, {});
      if (!_.isEqual(selectedTemplateFromPrevProps, selectedTemplateFromCurrentProps)) {
        this.updateMessageTemplateDetails(selectedTemplateFromCurrentProps);
      }
    }
    const selectedPhoneRefId = orgPhoneConsentInfo?.[selectedPhone?.Number]?.RefId;
    const deniedConsentJobId = basicInfoJobGuidToIdMapping?.[selectedPhoneRefId];
    if (
      selectedPhoneRefId &&
      (Object.keys(jobDetails ?? {})?.length === 0 || jobDetails?.JobGuid !== selectedPhoneRefId) &&
      deniedConsentJobId &&
      jobsBasicInfoById?.[deniedConsentJobId]
    ) {
      const jobId = basicInfoJobGuidToIdMapping[selectedPhoneRefId];
      this.setState({ jobDetails: jobsBasicInfoById[jobId] });
    }
  }

  componentWillUnmount() {
    this.markMessageAsRead();
  }

  updateMessageTemplateDetails = messageTemplateDetails => {
    this.setState({
      currentMessageInputContent: _.get(messageTemplateDetails, 'Body', null),
      isConsent: _.get(messageTemplateDetails, 'IsConsent', false),
      templateName: messageTemplateDetails?.Name,
    });
  };

  setTemplateName = value => {
    this.setState({ templateName: value });
  };

  async onSendNewMessage(values, text, onReadPhoneNumberSMS) {
    const { sendMessage, conversationId, candidate, jobId } = this.props;
    const { templateId } = this.state;
    const modifiedMessageContent = text.replace(/ /g, ' '); // eslint-disable-line
    const message = {
      To: [values.phoneNumber],
      Body: modifiedMessageContent,
      TemplateId: templateId,
      IsConsentMessage: values.IsConsentMessage,
      ConsentOption: config.consentOption,
    };
    this.setState({
      currentMessageInputContent: '',
      isConsent: false,
      templateId: '',
    });
    await sendMessage(jobId, message, conversationId, candidate);
    onReadPhoneNumberSMS();
  }

  setIsJunkWordFound = value => {
    this.setState({ isJunkWordFound: value });
  };

  setJunkWordsFound = value => {
    this.setState({ junkWords: value });
  };

  onUpdateMessageContent({ htmlContent }) {
    this.setState({
      currentMessageInputContent: removeUnicodeQuotation(htmlContent),
    });
    debouncedCheckForJunkWordsInText(
      parseTextForJunkDetection(htmlContent),
      this.setIsJunkWordFound,
      this.setJunkWordsFound
    );
  }

  onReadSMS = phoneNumber => {
    const { conversationId } = this.props;
    readPhoneNumberSMS(conversationId, phoneNumber);
  };

  onChangePhone(phoneValue) {
    const { conversationId, fetchMessages, setMessageConversationApiStatus } = this.props;
    setMessageConversationApiStatus('INPROGRESS');
    const {
      contact: { Phones: phones },
    } = this.state;

    const selectedPhone = (phones || []).find(p => p?.Number === phoneValue) || null;
    if (!selectedPhone) return;
    this.setState({
      selectedPhone,
      currentMessageInputContent: '',
      templateName: undefined,
    });
    if (this.connectRef.current) {
      const connectRef = this.connectRef.current;
      const { form } = connectRef.props;
      const dropdownPhoneNumber = form.getFieldValue('phoneNumber');
      if (dropdownPhoneNumber !== selectedPhone) form.setFieldsValue({ phoneNumber: selectedPhone.Number });
    }
    if (conversationId && selectedPhone) {
      fetchMessages(conversationId, 'SMS', selectedPhone.Number);
      this.onReadSMS(phoneValue);
    }
  }

  onCloseMessageComposer() {
    this.setState({
      currentMessageInputContent: '',
      templateName: undefined,
    });
  }

  onTemplateSelect(templateId, isConsent = null) {
    const { consentMessageTemplates, nonConsentMessageTemplates, messageTemplates, getTextTemplate } = this.props;

    if (templateId !== undefined) {
      getTextTemplate(templateId);
      const selectedTemplate = getSelectedMessageTemplateDetails(
        templateId,
        isConsent,
        consentMessageTemplates,
        nonConsentMessageTemplates,
        messageTemplates
      );
      this.setState({
        currentMessageInputContent: _.get(selectedTemplate, 'Body', null),
        templateId,
        isConsent: _.get(selectedTemplate, 'IsConsent', false),
        templateName: selectedTemplate?.Name,
      });
    } else {
      this.setState({
        currentMessageInputContent: '',
        templateId: '',
        isConsent: '',
        templateName: undefined,
      });
    }
  }

  markMessageAsRead() {
    const { conversationId, markConversationAsRead, markNotificationAsRead, jobGuid } = this.props;
    if (conversationId) {
      markConversationAsRead(conversationId, 'SMS', jobGuid);
      markNotificationAsRead({
        Source: 'Connect',
        Types: ['SMS'],
        RefId: conversationId,
      });
    }
  }

  getMessageConsentStatus = () => {
    const { contact, selectedPhone } = this.state;
    if (!contact) return undefined;
    return selectedPhone ? selectedPhone.MessageConsentStatus : undefined;
  };

  getMessageConsentInfo = () => {
    const { contact, selectedPhone } = this.state;
    if (!contact) return undefined;
    return selectedPhone ? selectedPhone.MessageConsentInfo : undefined;
  };

  getConsentStatusInfoBanner = () => {
    const {
      candidate = {},
      candidateStatus,
      whiteLabelInfo,
      featureToggleList,
      usersByGuId,
      orgPhoneConsentInfo,
    } = this.props;

    const revealActiveChannelSourceName = featureToggleList.RevealPortalsUnderGroup?.IsEnabled;
    const portal = getPortalName(candidate?.Sources, revealActiveChannelSourceName);
    const appliedTime = candidate.AppliedTime;
    const AppName = _.get(whiteLabelInfo, ['AppName'], 'Arya');
    const {
      contact: { ConsentStatus: consentStatus, Subscription: subscription },
      selectedPhone,
      isCommunicationAllowed,
      jobDetails,
    } = this.state;
    const consentInfoClassName = 'consent-status-info-message-360-view';

    const messageConsentStatus = this.getMessageConsentStatus();
    const messageConsentInfo = this.getMessageConsentInfo();
    const candidateName = candidate.Name;
    const nameParts = (candidateName || '').split(' ');
    const firstName = nameParts && nameParts.length > 0 ? nameParts[0] : '';

    const errorMessageStyle = { marginTop: '0px' };
    const orgMessageConsentInfoDetails = orgPhoneConsentInfo?.[selectedPhone?.Number];

    if (orgMessageConsentInfoDetails?.ConsentStatus === 'Denied' && orgMessageConsentInfoDetails?.IsOrgLevel) {
      if (!jobDetails.JobTitle) return <Skeleton loading active paragraph={{ rows: 1, width: '60' }}></Skeleton>;
      const updatedTime = moment.utc(orgMessageConsentInfoDetails.UpdatedTime).local().format('DD-MMM-YYYY hh:mm A');
      return (
        <div className={`${consentInfoClassName} consent-denied`}>
          <Icon type="close-circle" theme="filled" />
          <span>{`Looks like ${orgMessageConsentInfoDetails?.Name} has unsubscribed to receive further TEXT from ${updatedTime} as per the outreach made for ${jobDetails.JobTitle} - ${jobDetails.JobId}.`}</span>{' '}
        </div>
      );
    }

    if (!isCommunicationAllowed) {
      const _subscription = subscription
        ? { ...subscription, CreatedByName: usersByGuId[subscription.CreatedBy]?.FullName }
        : null;
      return getCommunicationTabsErrorMessage({
        candidateStatus,
        candidateName,
        style: errorMessageStyle,
        subscription: _subscription,
      });
    }

    if (
      appliedTime &&
      messageConsentStatus !== 'Denied' &&
      !lastConversationWithCandidate &&
      portal.toLowerCase() !== 'social'
    ) {
      return (
        <div className={`${consentInfoClassName}`}>
          <Icon type="info-circle" theme="filled" />
          <span>
            <FormattedMessage {...message.smsConsentNotRequired} values={{ firstName }} />
          </span>
        </div>
      );
    }
    if (
      !messageConsentStatus &&
      ((selectedPhone && selectedPhone.IsAddedByUser) || !consentStatus || consentStatus === 'Approved')
    ) {
      return (
        <div className={`${consentInfoClassName}`}>
          <Icon type="info-circle" theme="filled" />
          <span>
            <FormattedMessage {...message.consentRequired} values={{ firstName }} />
          </span>
        </div>
      );
    }
    // if ((messageConsentStatus === 'Denied' || consentStatus === 'Denied') && messageConsentInfo?.IsOrgLevel) {
    //   if (!jobDetails.JobTitle) return <Skeleton loading active paragraph={{ rows: 1, width: '60' }}></Skeleton>;
    //   const updatedTime = moment.utc(messageConsentInfo.UpdatedTime).local().format('DD-MMM-YYYY hh:mm A');
    //   return (
    //     <div className={`${consentInfoClassName} consent-denied`}>
    //       <Icon type="close-circle" theme="filled" />
    //       <span>{`Looks like ${candidateName} has unsubscribed to receive further TEXT from ${updatedTime} as per the outreach made for ${jobDetails.JobTitle} - ${jobDetails.JobId}.`}</span>{' '}
    //     </div>
    //   );
    // }
    if ((messageConsentStatus === 'Denied' || consentStatus === 'Denied') && !messageConsentInfo?.IsOrgLevel) {
      // if (!jobDetails.JobTitle) return <Skeleton loading active paragraph={{ rows: 1, width: '60' }}></Skeleton>;
      return (
        <div className="consent-status-info-message consent-denied">
          <Icon type="close-circle" theme="filled" />
          <span>{`We're sorry, but ${firstName} has declined consent. You may no longer communicate on this number via Arya.`}</span>
        </div>
      );
    }
    if (messageConsentStatus === 'Failed' || consentStatus === 'Failed') {
      return (
        <div className={`${consentInfoClassName} consent-denied`}>
          <Icon type="close-circle" theme="filled" />
          <span>
            <FormattedMessage {...message.consentFailed} values={{ firstName }} />
          </span>
        </div>
      );
    }
    if (messageConsentStatus === 'Pending' || consentStatus === 'Pending') {
      return (
        <div className={`${consentInfoClassName} consent-pending`}>
          <Icon type="clock-circle" theme="filled" />
          <span>
            <FormattedMessage {...message.consentPending} values={{ firstName }} />
          </span>
        </div>
      );
    }
    if (selectedPhone?.IsUnsubscribeByUser) {
      return (
        <div className={`${consentInfoClassName} consent-denied`}>
          <Icon type="close-circle" theme="filled" />
          <span>
            <FormattedMessage {...message.recruiterHasUnsubscribedLabel} /> {selectedPhone.Number}
          </span>
        </div>
      );
    }
    return null;
  };

  getCreditInfoBanner = () => {
    const { availableCredits, MessageConversationsofAllPhonenumbers } = this.props;
    const { selectedPhone } = this.state;
    const availableCreditInfo = getAvailableCredits(availableCredits, 'SMS');
    const consentInfoClassName = 'consent-status-info-message-360-view';
    const currentPhonenumberMessages = MessageConversationsofAllPhonenumbers[selectedPhone?.Number] || [];

    if (availableCreditInfo === 'N/A') {
      return (
        <div className={`${consentInfoClassName} consent-denied`} style={{ marginTop: '10px' }}>
          <Icon type="info-circle" theme="filled" />
          <span>
            Your organization does not have access to the Text plan. Please contact your admin or{' '}
            <a href="mailto:support@leoforce.com">support@leoforce.com</a> for assistance.
          </span>
        </div>
      );
    }

    if (availableCreditInfo === 0) {
      return (
        <div className={`${consentInfoClassName} consent-denied`} style={{ marginTop: '10px' }}>
          <Icon type="info-circle" theme="filled" />
          <span>
            You do not have enough credits to send a text. Please contact your admin or{' '}
            <a href="mailto:support@leoforce.com">support@leoforce.com</a> for assistance.
          </span>
        </div>
      );
    }

    if (currentPhonenumberMessages.length > 0) return null;

    if (availableCreditInfo <= 10) {
      return (
        <div className={`${consentInfoClassName} consent-denied`} style={{ marginTop: '10px' }}>
          <Icon type="info-circle" theme="filled" />
          <span style={{ color: 'red' }}>
            Your text credits are low. Please add credits or contact your admin or{' '}
            <a href="mailto:support@leoforce.com">support@leoforce.com</a> for assistance.
          </span>
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      candidate,
      currentJobDetails,
      // messageTemplates,
      consentMessageTemplates,
      nonConsentMessageTemplates,
      mergeTags,
      jobId,
      availableProviders,
      availableCredits,
      featureToggleList,
      candidateStatus,
      setInvalidTemplateNotification,
      CandidatesConnectInfo,
      candidateContext = 'job',
      version,
      isCandidateViewHeaderVisible,
      setConversationMessagesApiStatus,
      addContact,
      jobGuid,
      isComposeMessageModalVisible,
      setMessageComposeModalVisibility,
      phoneNumber,
      setPhoneNumber,
      isConnectContentPane,
      MessageConversationsofAllPhonenumbers,
      getSendMessageApiStatus,
      fetchBulkUnReadConversationsCount,
      conversationId,
      fetchMessages,
    } = this.props;
    const {
      currentMessageInputContent,
      messages,
      showFetchContact,
      selectedPhone,
      contact,
      isConsent,
      isCommunicationAllowed,
      templateName,
      contact: { ConsentStatus: consentStatus },
      isJunkWordFound,
      junkWords,
    } = this.state;

    const filteredMergeTags = getFilteredMergeTags(
      mergeTags,
      {
        CustomizeJobUrl: featureToggleList.CustomizeJobUrl,
        JobSummary: featureToggleList.JobSummary,
        CustomizeJobNotInterestedUrl: featureToggleList.CustomizeJobNotInterestedUrl,
      },
      version,
      currentJobDetails
    );
    const { JobCode: jobCode } = currentJobDetails;
    const mergeTagsForMessage = filteredMergeTags.filter(mergeTag => mergeTag.Scopes.includes('Sms'));
    const isContactFetchEnabled = _.get(featureToggleList, ['ContactFetch', 'IsEnabled'], false);
    const availableCreditInfo = getAvailableCredits(availableCredits, 'SMS');

    const showCommunicationDisabledAlert = getIsCandidateCommunicationDisabled(
      candidate.PersonId,
      CandidatesConnectInfo
    );

    if (showCommunicationDisabledAlert) {
      const alertContent = getCommunicationDisabledContent();
      return <UserAlertWithWrapper header={alertContent.header} content={alertContent.body} />;
    }

    if (showFetchContact) {
      const candidateName = candidate.Name;
      return isContactFetchEnabled ? (
        <FetchContactInfo
          mediaType="text"
          candidateName={candidateName}
          availableProviders={availableProviders}
          candidate={candidate}
          contact={contact}
          currentJobDetails={currentJobDetails}
          isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
        />
      ) : (
        <div className="chat-empty">
          <Empty
            description={
              <div className="chat-empty-text">
                <FormattedMessage {...message.noMessageHistoryAvailableLabel} />
              </div>
            }
            image={<TextIcon style={{ fontSize: '40px', color: '#CCC' }} />}
          />
        </div>
      );
    }

    const showSkeleton =
      !isComposeMessageModalVisible &&
      setConversationMessagesApiStatus &&
      setConversationMessagesApiStatus !== 'COMPLETED';

    if (showSkeleton) return <Skeleton active paragraph={{ rows: 6 }} />;
    const isCandidate360EmptyScreenVisible = !messages?.length && !isComposeMessageModalVisible;
    if (isCandidate360EmptyScreenVisible && isCommunicationAllowed) {
      let messageConsentStatus = this.getMessageConsentStatus();
      if (!messageConsentStatus && messages && messages.find(m => m.To === selectedPhone.Number)) {
        messageConsentStatus = 'Pending';
      }
      const isValidConsentStatus =
        !consentStatus || consentStatus === 'Approved' || (selectedPhone && selectedPhone.IsAddedByUser);
      const consentBanner = this.getConsentStatusInfoBanner();
      const showConsentBanner = isValidConsentStatus || messageConsentStatus === 'Approved' ? null : consentBanner;
      const emptyScreenStyle = getEmptyContactsTabScreenStyle({
        consentInforBanner: showConsentBanner,
        isCandidateViewHeaderVisible,
      });
      const { iconStyle, infoMessageStyle, contactInfoStyle } = emptyScreenStyle || {};
      return (
        <>
          <div className={styles.consentInfoBanner360}>{showConsentBanner}</div>
          <Candidate360EmailAndMessageEmptyScreen
            setComposeModalVisibility={setMessageComposeModalVisibility}
            activeTab="message"
            isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
            iconStyle={iconStyle}
            isDisabled={!!showConsentBanner}
            infoMessageStyle={infoMessageStyle}
            contactInfoStyle={contactInfoStyle}
          />
        </>
      );
    }

    const currentValues = {
      messageInputValue: currentMessageInputContent,
      selectedPhone,
      isConsent,
      isCommunicationAllowed,
    };
    const eventCallbacks = {
      onSend: this.onSendNewMessage,
      onUpdateMessage: this.onUpdateMessageContent,
      onChangePhone: this.onChangePhone,
      onTemplateSelect: this.onTemplateSelect,
    };
    return (
      <ConnectMessageWindow
        contact={contact}
        wrappedComponentRef={this.connectRef}
        messages={messages}
        jobTitle={currentJobDetails?.JobTitle || ''}
        phonenumberMessages={MessageConversationsofAllPhonenumbers[selectedPhone?.Number] || []}
        candidateStatus={candidateStatus}
        eventCallbacks={eventCallbacks}
        currentValues={currentValues}
        consentMessageTemplates={consentMessageTemplates || []}
        nonConsentMessageTemplates={nonConsentMessageTemplates || []}
        mergeTags={mergeTagsForMessage}
        jobId={jobId}
        jobCode={jobCode}
        candidateName={candidate.Name}
        candidate={candidate}
        availableCredits={availableCredits}
        featureToggleList={featureToggleList}
        version={version}
        templateName={templateName}
        setInvalidTemplateNotification={setInvalidTemplateNotification}
        candidateContext={candidateContext}
        isComposeMessageModalVisible={isComposeMessageModalVisible}
        setMessageComposeModalVisibility={setMessageComposeModalVisibility}
        isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
        addContact={addContact}
        jobGuid={jobGuid}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        getConsentStatusInfoBanner={this.getConsentStatusInfoBanner}
        getCreditInfoBanner={this.getCreditInfoBanner}
        getMessageConsentStatus={this.getMessageConsentStatus}
        isConnectContentPane={isConnectContentPane}
        onCloseMessageComposer={this.onCloseMessageComposer}
        messageConversationApiStatus={setConversationMessagesApiStatus}
        sendMessageApiStatus={getSendMessageApiStatus}
        onReadSMS={this.onReadSMS}
        fetchBulkUnReadConversationsCount={fetchBulkUnReadConversationsCount}
        conversationId={conversationId}
        setTemplateName={this.setTemplateName}
        availableCreditInfo={availableCreditInfo}
        isJunkWordFound={isJunkWordFound}
        junkWords={junkWords}
        setIsJunkWordFound={this.setIsJunkWordFound}
        fetchMessages={fetchMessages}
        selectedPhoneNumber={selectedPhone?.Number}
      />
    );
  }
}
export { MessageWindowContainer as MessageWindowWithoutContainer };
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MessageWindowContainer);
