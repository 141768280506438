import React from 'react';
import _ from 'lodash';
import { Checkbox } from 'antd';
import { EllipsedText } from '../../../Utils/TextUtils';

const CheckboxItem = props => {
  const { value, text, count, isHighlighted, maxTextLength, style, onChange, checked, shouldPreserveCasing, indeterminate } = props;
  return (
    <div style={{ display: 'flex', alignItems: 'center', ...style }}>
      <Checkbox
        value={value}
        style={{ marginRight: '10px' }}
        checked={checked}
        onChange={val => {
          if (onChange) onChange(val);
        }}
        indeterminate={indeterminate}
      />
      <div>
        <EllipsedText text={shouldPreserveCasing ? text : _.capitalize(text)} maxTextLength={maxTextLength} /> ({count})
      </div>
    </div>
  );
};

export default React.memo(CheckboxItem);
