import React from 'react';
import { connect } from 'react-redux';
import { Checkbox, Button, Form, Popover, Tooltip, Tabs, Icon, Radio } from 'antd';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import {
  getSourceDisplayName,
  getMaskedSourceFilters,
  getSourceFiltersFromMaskedFilters,
} from '../../../Utils/SourceUtils';
import RatingIcon from '../../../Components/Rating/Rating';
import MoverTitle from '../../../Components/Mover/Mover';
import Tickbox from '../../../Components/Tickbox/Tickbox';
import EmailStatsFilter from '../../../Components/EmailStatsFilter/EmailStatsFilter';
import FilterIconWithTooltip from '../../../Components/Common/FilterIconWithTooltip/FilterIconWithTooltip';
import { isPulseUser } from '../../../Utils/ConfigUtils';
import { getActualSourcingStats } from '../../../Utils/JobSourcingStatsUtil';
import styles from './CandidateAggregationFilter.module.scss';
import SegmentEmailStatsFilter from '../../../Components/EmailStatsFilter/SegmentEmailStatsFilter';
import ScoutingAgentFilter from './ScoutingAgentFilter/ScoutingAgentFilter';
import store from '../../../store';
import CandidateAdvanceFilterGroupHeader from './CandidateAdvanceFilters/CandidateAdvanceFilterGroupHeader';
import { getInitialCandidateCountBySource } from '../../../Reducers/JobReducer';
import * as CandidateActions from '../../../Actions/CandidateActions';
import FilterAryaSearch from '../../../Components/FilterAryaSearch/FilterAryaSearch';
import * as JobCandidatesTabActions from '../../../Actions/JobCandidatesTabActions';
import { getCandidateCollapsedFilters } from '../../../Reducers/JobCandidatesTabReducer';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import { getAdminFeatures } from '../../../Reducers/UserReducer';
import { getCandidatePublishStatus } from '../../../Utils/CandidatePublishUtils';
import { EllipsedText } from '../../../Utils/TextUtils';
import message from '../CandidateFiltersMessage';

const { Item } = Form;
const { TabPane } = Tabs;

const mapDispatchToProps = {
  setCandidateCountBySource: CandidateActions.setCandidateCountBySource,
  seCandidateCollapsedFilters: JobCandidatesTabActions.seCandidateCollapsedFilters,
  setCandidateCollapsedFilterStatus: JobCandidatesTabActions.setCandidateCollapsedFilterStatus,
};

const mapStateToProps = (state, props) => ({
  initialCandidatesCountBySource: getInitialCandidateCountBySource(state, props.jobId),
  candidateCollapsedFilters: getCandidateCollapsedFilters(state, props.jobId),
  featureToggleList: getFeatureToggleList(state),
  adminFeature: getAdminFeatures(state),
});

const initialState = {
  candidateAdvanceFilters: [],
  aggregationFilterVisible: false,
  isCollapsedFilterApplied: false,
  isClearButtonClicked: false,
  candidateRatings: {
    Good: false,
    Better: false,
    Best: false,
  },
  selectedScoutingAgents: [],
  candidateMovers: {
    LowMover: false,
    MediumMover: false,
    HighMover: false,
    LowNonMover: false,
    MediumNonMover: false,
    HighNonMover: false,
  },
  tabName: 'source',
  isApplyButtonDisabled: false,
};

const defaultAggregationFilter = {
  Industries: [],
  ShortlistedBy: [],
  IsFavorite: null,
  IsViewed: null,
  Movers: [],
  Ratings: [],
  Sources: [],
  EmailStats: [],
  ScoutingAgentIds: [],
  ViewStatus: 'ALL_CANDIDATES',
  RecommendationSources: null,
};

const isPulse = isPulseUser();

class CandidateFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = _.cloneDeep(initialState);
  }

  componentDidMount() {
    this.setDefaultAggregationFilters();
  }

  componentDidUpdate(prevProps) {
    const { currentPipelineFilter, activeTab, currentUserViewedStatusFlag, candidateContext, isFilterApplied, form } =
      this.props;
    const aggregationFilterKeys = Object.keys(defaultAggregationFilter);
    const currentAggregationFilter = _.pick(currentPipelineFilter, aggregationFilterKeys);
    const previousAggregationFilter = _.pick(prevProps.currentPipelineFilter, aggregationFilterKeys);
    const previousAdvanceFilter = prevProps?.currentPipelineFilter?.AdvanceFilterQuery;
    const currentAdvanceFilter = currentPipelineFilter?.AdvanceFilterQuery;
    if (candidateContext !== 'segment') {
      if (
        !_.isEqual(currentAggregationFilter, previousAggregationFilter) ||
        activeTab !== prevProps.activeTab ||
        currentUserViewedStatusFlag !== prevProps.currentUserViewedStatusFlag
      ) {
        this.setDefaultAggregationFilters();
      }
      if (activeTab !== prevProps.activeTab && _.isEqual(currentAggregationFilter, previousAggregationFilter)) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ tabName: 'source' });
      }
    } else if (!_.isEqual(previousAdvanceFilter, currentAdvanceFilter)) {
      this.setDefaultAggregationFilters();
    }
  }

  setDefaultAggregationFilters = async () => {
    const {
      form,
      currentPipelineFilter,
      setIsFilterApplied,
      currentUserViewedStatusFlag,
      candidateContext,
      seCandidateCollapsedFilters,
      jobId,
    } = this.props;
    const aggregationFilterKeys = Object.keys(defaultAggregationFilter);
    const currentAggregationFilter = _.pick(currentPipelineFilter, aggregationFilterKeys);
    const isAggregationFilterApplied =
      !_.isEqual(currentAggregationFilter, defaultAggregationFilter) || currentUserViewedStatusFlag;
    const { candidateRatings, candidateMovers } = _.cloneDeep(initialState);
    form.setFieldsValue({
      industry: currentPipelineFilter.Industries,
      shortlistedBy: currentPipelineFilter.ShortlistedBy,
      viewStatus: currentPipelineFilter.ViewStatus || 'ALL_CANDIDATES',
      favourite:
        currentPipelineFilter?.IsFavorite === 0 || currentPipelineFilter?.IsFavorite === 1
          ? [currentPipelineFilter.IsFavorite]
          : currentPipelineFilter?.IsFavorite,
      emailStats: currentPipelineFilter.EmailStats,
      source: (currentPipelineFilter.Sources || []).map(source => source.Name || source.Portal || source.Group),
      boosted: currentPipelineFilter.RecommendationSources?.length ? 'boosted' : null,
      collapsedFilter: [],
    });
    this.setState({ isCollapsedFilterApplied: false });
    seCandidateCollapsedFilters({ jobId, filters: [] });
    Object.keys(candidateRatings).forEach(rating => {
      candidateRatings[rating] = currentPipelineFilter.Ratings.includes(rating);
    });
    Object.keys(candidateMovers).forEach(mover => {
      candidateMovers[mover] = currentPipelineFilter.Movers.includes(mover);
    });
    if (candidateContext !== 'segment') {
      setIsFilterApplied(isAggregationFilterApplied);
      this.setState({ candidateRatings, candidateMovers });
    } else {
      setIsFilterApplied(currentPipelineFilter?.AdvanceFilterQuery !== undefined);
    }
  };

  customIsEqual = (a, b) => {
    if (!a && !b) {
      return true;
    }
    return _.isEqual(a, b);
  };

  applyAggregationFilter = async filters => {
    const { applyCandidateFilter, currentPipelineFilter, candidateContext } = this.props;
    const {
      industries,
      moverPredictions,
      ratings,
      sources,
      emailStats,
      shortlistedBy,
      favourite,
      currentCandidateViewedFormValue,
      scoutingAgentIds,
      advanceFilterQuery,
      viewStatus,
      recommendationSources,
    } = filters;
    let isFilterModified =
      !_.isEqual(currentPipelineFilter.ShortlistedBy, shortlistedBy) ||
      !_.isEqual(currentPipelineFilter.IsFavorite, favourite) ||
      !_.isEqual(currentPipelineFilter.Industries, industries) ||
      !_.isEqual(currentPipelineFilter.Movers, moverPredictions) ||
      !_.isEqual(currentPipelineFilter.Ratings, ratings) ||
      !_.isEqual(currentPipelineFilter.Sources, sources) ||
      !_.isEqual(currentPipelineFilter.EmailStats, emailStats) ||
      !_.isEqual(currentPipelineFilter.ViewStatus, viewStatus) ||
      !_.isEqual(currentPipelineFilter.ScoutingAgentIds, scoutingAgentIds) ||
      !_.isEqual(currentPipelineFilter.RecommendationSources, recommendationSources);
    if (candidateContext === 'segment') {
      const currentFilterGroups = currentPipelineFilter?.AdvanceFilterQuery?.FilterGroups;
      const newFilterGroups = advanceFilterQuery?.FilterGroups;
      if (currentFilterGroups && newFilterGroups) {
        const isEqual = !_.isEqual(JSON.stringify(currentFilterGroups), JSON.stringify(newFilterGroups));
        isFilterModified = isEqual;
      } else {
        isFilterModified = !_.isEqual(currentFilterGroups, newFilterGroups);
      }
    }

    if (isFilterModified) {
      const modifiedFilters = {
        Industries: industries,
        ShortlistedBy: shortlistedBy,
        IsFavorite: favourite,
        Movers: moverPredictions,
        Ratings: ratings,
        Sources: sources,
        EmailStats: emailStats,
        ScoutingAgentIds: scoutingAgentIds,
        AdvanceFilterQuery: advanceFilterQuery,
        ViewStatus: viewStatus,
        RecommendationSources: recommendationSources,
      };

      await applyCandidateFilter({
        modifiedFilters,
        isFilterModified,
        isCurrentCandidateViewedFlag: true,
        currentCandidateViewedFormValue,
      });
    }
  };

  filterIconClick = () => {
    this.setState({ isClearButtonClicked: false });
  };

  isKeySelected = (obj, key) => {
    const value = obj[key];
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return !!value;
  };

  onSubmit = async event => {
    event.preventDefault();
    const { candidateRatings, candidateMovers, selectedScoutingAgents, candidateAdvanceFilters } = this.state;
    const {
      form,
      candidateAggs,
      aryaVersion,
      setCandidateCollapsedFilterStatus,
      jobId,
      seCandidateCollapsedFilters,
      featureToggleList,
      setCandidateCountBySource,
      initialCandidatesCountBySource,
    } = this.props;
    const scoutingAgents = selectedScoutingAgents.filter(id => id !== 'all');
    const aggSources = candidateAggs.Source;
    const newRatings = [];
    const moverPredictions = form.getFieldValue('moverPrediction') || [];
    Object.keys(candidateRatings).forEach(candidateRating => {
      if (candidateRatings[candidateRating]) {
        newRatings.push(candidateRating);
      }
    });
    const formValues = {
      ...form.getFieldsValue(),
      moverPrediction: moverPredictions,
      rating: newRatings,
      scoutingAgentIds: scoutingAgents,
    };
    const sources = getSourceFiltersFromMaskedFilters(aryaVersion, candidateAggs.Source, formValues.source);
    const advanceFilterQuery =
      candidateAdvanceFilters?.length > 0 ? { FilterGroups: candidateAdvanceFilters } : undefined;
    this.setState({ aggregationFilterVisible: false });
    const isBoostFilterApplied = formValues.boosted?.length > 0;
    await this.applyAggregationFilter({
      industries: formValues.industry || [],
      shortlistedBy: formValues.shortlistedBy || [],
      favourite: formValues?.favourite?.length !== 1 ? null : formValues.favourite[0],
      moverPredictions,
      ratings: newRatings,
      sources: sources.map(sourceName => {
        const aggSource = aggSources.find(x => x.Value === sourceName) || {};
        const source = aggSource.Group ? { Group: aggSource.Group } : { Portal: aggSource.Portal };
        if (aggSource.Portal && aggSource.Value !== aggSource.Portal) {
          source.Name = sourceName;
        }
        return source;
      }),
      emailStats: formValues.emailStats || [],
      scoutingAgentIds: formValues.scoutingAgentIds || [],
      advanceFilterQuery,
      viewStatus: formValues.viewStatus,
      recommendationSources: isBoostFilterApplied ? ['AssistedSourcing'] : null,
    });

    const isFilterSelected = Object.keys(formValues).some(key => this.isKeySelected(formValues, key));
    if (!isFilterSelected) setCandidateCountBySource({ candidateCountBySource: initialCandidatesCountBySource, jobId });
    if (featureToggleList?.AdvanceSearchV2?.IsEnabled) {
      const collapsedFilterField = 'collapsedFilter';
      const selectedKeys = form.getFieldValue(collapsedFilterField) || [];
      setCandidateCollapsedFilterStatus('INPROGRESS');
      await seCandidateCollapsedFilters({ jobId, filters: selectedKeys });
      setCandidateCollapsedFilterStatus('COMPLETED');
      if (selectedKeys.length) this.setState({ isCollapsedFilterApplied: true });
      else this.setState({ isCollapsedFilterApplied: false });
    }
  };

  handleSubmitButtonDisable = candidateAdvanceFilters => {
    const { isApplyButtonDisabled } = this.state;
    if (isApplyButtonDisabled) {
      return true;
    }
    return candidateAdvanceFilters?.some(item => {
      return (
        Array.isArray(item.FilterItems) &&
        item.FilterItems?.some(filterItem => {
          return (
            filterItem?.AttributeName?.trim() === undefined ||
            filterItem?.Operator?.trim() === undefined ||
            filterItem?.AttributeValue?.trim() === undefined ||
            filterItem?.AttributeValue?.trim() === ''
          );
        })
      );
    });
  };

  onClear = async () => {
    const {
      setCandidateCountBySource,
      initialCandidatesCountBySource,
      jobId,
      setCandidateCollapsedFilterStatus,
      seCandidateCollapsedFilters,
      featureToggleList,
    } = this.props;
    this.setState({ aggregationFilterVisible: false, isClearButtonClicked: true });
    await this.applyAggregationFilter({
      industries: [],
      shortlistedBy: [],
      favourite: null,
      moverPredictions: [],
      ratings: [],
      sources: [],
      emailStats: [],
      currentCandidateViewedFormValue: false,
      scoutingAgentIds: [],
      viewStatus: 'ALL_CANDIDATES',
      recommendationSources: null,
    });
    const { form } = this.props;
    setCandidateCountBySource({ candidateCountBySource: initialCandidatesCountBySource, jobId });
    this.setSelectedScoutingAgents([]);
    form.resetFields();
    if (featureToggleList?.AdvanceSearchV2?.IsEnabled) {
      setCandidateCollapsedFilterStatus('INPROGRESS');
      await seCandidateCollapsedFilters({ jobId, filters: [] });
      form.setFieldsValue({ collapsedFilter: [] });
      setCandidateCollapsedFilterStatus('COMPLETED');
    }
  };

  onRatingChange = (className, value) => {
    const { candidateRatings } = this.state;
    const clonedCandidateRatings = _.cloneDeep(candidateRatings);
    clonedCandidateRatings[className] = value;
    this.setState({ candidateRatings: clonedCandidateRatings });
  };

  onMoverChange = (className, value) => {
    const { candidateMovers } = this.state;
    const clonedCandidateMovers = _.cloneDeep(candidateMovers);
    clonedCandidateMovers[className] = value;
    this.setState({ candidateMovers: clonedCandidateMovers });
  };

  getIndustryFilters = () => {
    const { candidateAggs } = this.props;
    return candidateAggs.Industry.map(industry => (
      <div key={industry.Value} style={{ display: 'flex' }}>
        <Checkbox className={styles.aggregationCheckboxFilter} value={industry.Value}>
          <EllipsedText text={industry.Value} maxTextLength={31} />
          <span> ({industry.Count})</span>
        </Checkbox>
      </div>
    ));
  };

  getBoostedCandidateFilter = () => {
    const filter = {
      Name: <FormattedMessage {...message.aryaSourceCandidatesLabel} />,
      id: 'boosted',
    };

    return (
      <div key={filter.id}>
        <Checkbox value={filter.id}>
          <span>{filter.Name}</span>
        </Checkbox>
      </div>
    );
  };

  getCandidateViewedFilters = () => {
    return (
      <div className={styles.candidateViewStatus}>
        <Radio value="ALL_CANDIDATES">
          <FormattedMessage {...message.allCandidatesViewedOrNotLabel} />
        </Radio>
        <Radio value="VIEWED_BY_ANYONE">
          <FormattedMessage {...message.viewedByAnyoneLabel} />
        </Radio>
        <Radio value="NOT_YET_VIEWED">
          <FormattedMessage {...message.notYetViewedLabel} />
        </Radio>
        <Radio value="VIEWED_BY_ME">
          <FormattedMessage {...message.viewedByMeLabel} />
        </Radio>
      </div>
    );
  };

  getShortlistedByFilters = () => {
    const { candidateAggs } = this.props;
    return candidateAggs.Shortlist.map(shortlist => (
      <div key={shortlist.Id}>
        <Checkbox value={shortlist.Id}>
          <span>
            <Tooltip title={shortlist.Value}>
              <span className="shortlist-by-name">{shortlist.Value}</span>
            </Tooltip>
            ({shortlist.Count})
          </span>
        </Checkbox>
      </div>
    ));
  };

  getFavouriteFilters = () => {
    const { candidateAggs } = this.props;
    const favoriteCandidates = candidateAggs?.IsFavourite.find(filter => filter.Value === '1');
    const nonFavoriteCandidates = candidateAggs?.IsFavourite.find(filter => filter.Value === '0');
    const favoriteCandidatesCount = favoriteCandidates?.Count ?? 0;
    const nonFavoriteCandidatesCount = nonFavoriteCandidates?.Count ?? 0;

    return candidateAggs?.IsFavourite.map(favourite => {
      return (
        <div key={favourite.Value} style={{ display: 'flex' }}>
          <Checkbox value={parseInt(favourite.Value, 10)} className={styles.aggregationCheckboxFilter}>
            <span>
              {parseInt(favourite.Value, 10) ? (
                <FormattedMessage {...message.allBookmarkedFavouriteLabel} values={{ favoriteCandidatesCount }} />
              ) : (
                <FormattedMessage
                  {...message.allOtherThanBookmarkedNonFavoriteLabel}
                  values={{ nonFavoriteCandidatesCount }}
                />
              )}
            </span>
          </Checkbox>
        </div>
      );
    });
  };

  getSourceFilters = () => {
    const { candidateAggs, showVaultName, aryaVersion, whiteLabelInfo } = this.props;
    const state = store.getState();
    const userConfig = state?.ConfigReducer?.UserConfig ?? {};
    if (aryaVersion !== 'Lite') {
      return candidateAggs.Source.map(source => {
        const sourceDisplayName = getSourceDisplayName(
          { Name: source.Value, Portal: source.Portal, Group: source.Group },
          userConfig,
          showVaultName,
          {
            Name: 'CandidateAggFilter',
          },
          whiteLabelInfo
        );
        return (
          <div key={source.Value}>
            <Checkbox style={{ marginBottom: 8 }} value={source.Value}>
              <EllipsedText text={sourceDisplayName} maxTextLength={31} /> ({source.Count})
            </Checkbox>
          </div>
        );
      });
    }

    const maskedSourceFilters = getMaskedSourceFilters(candidateAggs.Source);

    return maskedSourceFilters.map(source => (
      <div>
        <Checkbox style={{ marginBottom: 8 }} value={source.value}>
          {source.value} ({source.count})
        </Checkbox>
      </div>
    ));
  };

  getMoversPredictionFilters = () => {
    const { candidateAggs } = this.props;
    return candidateAggs.MoversPrediction.map(moverPrediction => (
      <div key={moverPrediction.Value}>
        <Checkbox className={moverPrediction.Value} style={{ marginBottom: 8 }} value={moverPrediction.Value}>
          <MoverTitle type={moverPrediction.Value} /> ({moverPrediction.Count})
        </Checkbox>
      </div>
    ));
  };

  getCandidateRatingFilters = () => {
    const { candidateAggs } = this.props;
    const { candidateRatings } = this.state;
    return candidateAggs.CandidateRating.map(candidateRating => (
      <div key={candidateRating.Value}>
        <Tickbox
          className={candidateRating.Value}
          checked={candidateRatings[candidateRating.Value]}
          onChange={this.onRatingChange}
        >
          <div className="candidate-filter-tick-box">
            <div>
              <RatingIcon type={candidateRating.Value} />
            </div>
            <div>({candidateRating.Count})</div>
          </div>
        </Tickbox>
      </div>
    ));
  };

  getTabHeading = text => (
    <div className={styles.tabNameWrapper}>
      <div>{text}</div>
      <Icon type="right" className={styles.arrowIcon} />
    </div>
  );

  handleVisibleChange = aggregationFilterVisible => {
    this.setState({ aggregationFilterVisible });
  };

  onTabChange = activeKey => {
    this.setState({ tabName: activeKey });
  };

  handleCandidateAdvanceFilters = filtersArray => {
    this.setState({ candidateAdvanceFilters: filtersArray });
  };

  updateApplyButtonState = newState => {
    this.setState({ isApplyButtonDisabled: newState });
  };

  getAdvanceFilterPopover = () => {
    const { candidateAggs, filterFields, currentJobDetails } = this.props;
    const { candidateAdvanceFilters, isClearButtonClicked } = this.state;
    const { shortlistedCount } = getActualSourcingStats(currentJobDetails?.sourcingStats);
    return (
      <div style={{ width: '700px', maxHeight: '300px', minHeight: '300px', overflow: 'auto' }}>
        <CandidateAdvanceFilterGroupHeader
          candidateAggs={candidateAggs}
          filterFields={filterFields}
          candidateAdvanceFilters={candidateAdvanceFilters}
          handleCandidateAdvanceFilters={this.handleCandidateAdvanceFilters}
          isClearButtonClicked={isClearButtonClicked}
          updateApplyButtonState={this.updateApplyButtonState}
          shortlistedCount={shortlistedCount}
        />
      </div>
    );
  };

  getPopoverContent = (candidateContext, filterPopover) => {
    if (candidateContext === 'segment') {
      return this.getAdvanceFilterPopover();
    }
    return filterPopover;
  };

  getSegmentFilterPopover = () => {
    const { form, candidateAggs, emailStats } = this.props;
    const { tabName } = this.state;
    const { Source, Industry, IsFavourite } = candidateAggs;
    const sourceFilter = Source ? this.getSourceFilters() : undefined;
    const industryFilter = Industry ? this.getIndustryFilters() : undefined;
    const favouriteFilter = IsFavourite ? this.getFavouriteFilters() : null;
    const viewedStatusFilter = this.getCandidateViewedFilters();

    return (
      <Form onSubmit={this.onSubmit} className={styles.candidateAggregation}>
        <Tabs
          tabPosition="left"
          size="small"
          style={{ height: '350px' }}
          activeKey={tabName}
          onChange={this.onTabChange}
        >
          <TabPane tab={this.getTabHeading('Source')} key="source">
            <Item>
              {form.getFieldDecorator('source')(
                <Checkbox.Group className="checkbox-group">
                  <div className={styles.filterContent}>{sourceFilter}</div>
                </Checkbox.Group>
              )}
            </Item>
          </TabPane>
          <TabPane tab={this.getTabHeading('Industry')} key="industry">
            <Item>
              {form.getFieldDecorator('industry')(
                <Checkbox.Group className="checkbox-group">
                  <div className={styles.filterContent}>{industryFilter}</div>
                </Checkbox.Group>
              )}
            </Item>
          </TabPane>
          <TabPane tab={this.getTabHeading('Bookmark')} key="favourite">
            {form.getFieldDecorator('favourite')(
              <Checkbox.Group className="checkbox-group">
                <div className={styles.filterContent}>{favouriteFilter}</div>
              </Checkbox.Group>
            )}
          </TabPane>
          <TabPane tab={this.getTabHeading('Email Status')} key="email-stats">
            {form.getFieldDecorator('emailStats')(
              <Checkbox.Group className="checkbox-group">
                <div className={styles.filterContent}>
                  <SegmentEmailStatsFilter emailStats={emailStats} />
                </div>
              </Checkbox.Group>
            )}
          </TabPane>

          <TabPane tab={this.getTabHeading('Viewed')} key="candidate-viewed">
            <Item>
              {form.getFieldDecorator('viewStatus', {
                initialValue: 'ALL_CANDIDATES',
              })(
                <Radio.Group>
                  <div className={styles.filterContent}>{viewedStatusFilter}</div>
                </Radio.Group>
              )}
            </Item>
          </TabPane>
        </Tabs>
      </Form>
    );
  };

  setSelectedScoutingAgents = checkedList => this.setState({ selectedScoutingAgents: checkedList });

  render() {
    const {
      candidateAggs,
      form,
      candidates,
      isFilterApplied,
      candidateContext = 'job',
      totalCandidate,
      connectDetails,
      isLoading,
      jobId,
      candidateCollapsedFilters,
      featureToggleList,
      conversationStatsForJob,
      subscriptionStatsForJob,
    } = this.props;

    const { Industry, CandidateRating, MoversPrediction, Source, Shortlist, IsFavourite, ScoutingAgentId } =
      candidateAggs;
    const { aggregationFilterVisible, selectedScoutingAgents, candidateAdvanceFilters, isCollapsedFilterApplied } =
      this.state;
    const sourceFilter = Source ? this.getSourceFilters() : undefined;
    const industryFilter = Industry ? this.getIndustryFilters() : undefined;
    const boostedCandidateFilter = this.getBoostedCandidateFilter();
    const viewedStatusFilter = this.getCandidateViewedFilters();
    const favouriteFilter = IsFavourite ? this.getFavouriteFilters() : undefined;
    const shortlistedByFilter = Shortlist ? this.getShortlistedByFilters() : undefined;
    const candidateRatingFilter = CandidateRating ? this.getCandidateRatingFilters() : undefined;
    const moversPredictionFilter = MoversPrediction ? this.getMoversPredictionFilters() : undefined;
    const isShortlistedCandiateExists = Object.values(candidates).find(candidate => candidate.Status === 'Shortlisted');
    const disableSubmitButton = this.handleSubmitButtonDisable(candidateAdvanceFilters);
    const isScoutingAgentFilter = ScoutingAgentId && ScoutingAgentId.length > 0;
    const {
      AssistedCandidateIndicator: { IsEnabled: isAssistedCandidateIndicatorEnabled },
      AssistedSourcing: { IsEnabled: isAssistedSourcingEnabled },
    } = featureToggleList;
    const shotlistByFilter =
      !isPulse && isShortlistedCandiateExists ? (
        <Item>
          {form.getFieldDecorator('shortlistedBy')(
            <Checkbox.Group className="checkbox-group">
              <h3>
                <FormattedMessage {...message.shortlistedByLabel} />
              </h3>
              <div className="industry-filter">{shortlistedByFilter}</div>
            </Checkbox.Group>
          )}
        </Item>
      ) : null;

    const boostedCandidatesFilter =
      isAssistedCandidateIndicatorEnabled && isAssistedSourcingEnabled ? (
        <Item>
          {form.getFieldDecorator('boosted')(
            <Checkbox.Group className="checkbox-group">
              <div className={styles.boostedCandidatesFilter}>
                <h3 style={{ fontSize: '16px', padding: '0px' }}>
                  <FormattedMessage {...message.showLabel} />
                </h3>
              </div>
              <div className="boost-filter">{boostedCandidateFilter}</div>
            </Checkbox.Group>
          )}
        </Item>
      ) : null;
    const filterPopover = (
      <Form className="candidate-filter-popover-content" onSubmit={this.onSubmit}>
        <div style={{ display: 'flex' }}>
          <div className="candidate-filter-rating-mover">
            {!isScoutingAgentFilter && candidateAggs?.MoversPrediction?.length > 0 && (
              <div className="candidate-filter-mover-prediction">
                <Form.Item>
                  {form.getFieldDecorator('moverPrediction', { initialValue: [] })(
                    <Checkbox.Group className="checkbox-group">
                      <h3>
                        <FormattedMessage {...message.movePredictionLabel} />
                      </h3>
                      <div className="mover-prediction-match">{moversPredictionFilter}</div>
                    </Checkbox.Group>
                  )}
                </Form.Item>
              </div>
            )}
            <div className="candidate-filter-favourite">
              <Item>
                {form.getFieldDecorator('favourite')(
                  <Checkbox.Group className="checkbox-group">
                    <div className={styles.favouriteFilter}>
                      <h3 style={{ fontSize: '16px', padding: '0px' }}>
                        {' '}
                        <FormattedMessage {...message.bookmarkLabel} />
                      </h3>
                    </div>
                    <div className="favourite-filter">{favouriteFilter}</div>
                  </Checkbox.Group>
                )}
              </Item>
            </div>

            <div>
              <Item>
                {form.getFieldDecorator('viewStatus', {
                  initialValue: 'ALL_CANDIDATES',
                })(
                  <Radio.Group>
                    <h3 style={{ marginTop: '7px', fontSize: '16px', padding: '0px' }}>
                      <FormattedMessage {...message.viewedLabel} />
                    </h3>
                    <div className={styles.filterContent}>{viewedStatusFilter}</div>
                  </Radio.Group>
                )}
              </Item>
            </div>
            <div>{boostedCandidatesFilter}</div>
          </div>
          <div className="candidate-filter-industry">
            <Item>
              {form.getFieldDecorator('industry')(
                <Checkbox.Group className="checkbox-group">
                  <div className={styles.commonStyleForFilterTitles}></div>
                  <h3>
                    <FormattedMessage {...message.industryLabel} />
                  </h3>
                  <div className="industry-filter">{industryFilter}</div>
                </Checkbox.Group>
              )}
            </Item>
            {shotlistByFilter}

            <div className="candidate-filter-source">
              <Item>
                {form.getFieldDecorator('source')(
                  <Checkbox.Group className="checkbox-group">
                    <div className={styles.commonStyleForFilterTitles}>
                      <h3>
                        <FormattedMessage {...message.sourceLabel} />
                      </h3>
                    </div>
                    <div className="source-filter">{sourceFilter}</div>
                  </Checkbox.Group>
                )}
              </Item>
            </div>
            {featureToggleList?.AdvanceSearchV2?.IsEnabled ? (
              <div className="candidate-filter-source">
                <FilterAryaSearch
                  form={form}
                  candidateCollapsedFilters={candidateCollapsedFilters}
                  candidateContext={candidateContext}
                />
              </div>
            ) : null}
          </div>
          <div className="candidate-filter-industry">
            <Item>
              {form.getFieldDecorator('emailStats')(
                <Checkbox.Group className="checkbox-group">
                  <div className={styles.commonStyleForFilterTitles}>
                    <h3>
                      <FormattedMessage {...message.communicationsLabel} />
                    </h3>
                  </div>
                  <p>
                    <FormattedMessage {...message.totalCandidatesTotalCommunicationsLabel} />
                  </p>
                  <div className="industry-filter">
                    <EmailStatsFilter
                      totalCandidate={totalCandidate}
                      connectDetails={connectDetails}
                      isLoading={isLoading}
                      conversationStatsForJob={conversationStatsForJob}
                      subscriptionStatsForJob={subscriptionStatsForJob}
                    />
                  </div>
                </Checkbox.Group>
              )}
            </Item>
            <Item>
              {form.getFieldDecorator('scoutingAgentFilter')(
                <ScoutingAgentFilter
                  jobId={jobId}
                  selectedScoutingAgents={selectedScoutingAgents}
                  setSelectedScoutingAgents={this.setSelectedScoutingAgents}
                  ScoutingAgentId={ScoutingAgentId}
                />
              )}
            </Item>
          </div>
        </div>
      </Form>
    );
    const popoverContent = this.getPopoverContent(candidateContext, filterPopover);
    const additionalFilterStyles = candidates.length || isFilterApplied ? {} : { cursor: 'not-allowed', opacity: 0.3 };
    return (
      <Popover
        className="filter-popover"
        overlayClassName="candidate-filter-popover"
        content={candidates.length || isFilterApplied ? popoverContent : null}
        placement="bottomRight"
        trigger="click"
        align={{ offset: [10, 0] }}
        autoAdjustOverflow={false}
        visible={aggregationFilterVisible}
        onVisibleChange={this.handleVisibleChange}
        title={
          candidates.length || isFilterApplied ? (
            <div className="candidate-filter-aggregation">
              <div className={styles.CandidateFilterAggregationTitle}>
                <div className="candidate-filter-aggregation-title">
                  <FormattedMessage {...message.filtersLabel} />
                </div>
              </div>
              <div className="candidate-filter-apply-clear">
                <Button className="candidate-filter-clear-button" onClick={this.onClear}>
                  <FormattedMessage {...message.clearButtonLabel} />
                </Button>
                <Button
                  className="candidate-filter-apply-button"
                  type="primary"
                  disabled={disableSubmitButton}
                  onClick={this.onSubmit}
                >
                  <FormattedMessage {...message.applyButtonLabel} />
                </Button>
              </div>
            </div>
          ) : null
        }
      >
        <FilterIconWithTooltip
          onClick={this.filterIconClick}
          title={<FormattedMessage {...message.filtersLabel} />}
          isFilterApplied={isFilterApplied || isCollapsedFilterApplied}
          style={{ fontSize: 20, marginTop: 5, ...additionalFilterStyles }}
        />
      </Popover>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(CandidateFilter));
export { CandidateFilter as CandidateAggregationFilterWithoutForm };
