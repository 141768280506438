import React, { useState } from 'react';
import { useSelector, connect } from 'react-redux';
import { Affix } from 'antd';
import { FormattedMessage } from 'react-intl';
import InfoCircleIcon from '../../Icons/SuggestedIcons/InfoCircleIcon';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getDripStats, getAllPhoneNumbersOfCandidate } from '../../Reducers/ConnectReducer';
import ContactInformationContainer from '../../Containers/CandidateDrawer/ContactInformationContainer';
import CandidateProfileContactsContainer from '../../Containers/CandidateDrawer/CandidateProfileContactsContainer';
import styles from './CandidateContactsWrapper.module.scss';
import ComposeEmailAndMessageButton from '../Connect/ComposeEmailAndMessageButton/ComposeEmailAndMessageButton';
import { getEventNameByCandidateStatus } from '../../Analytics/Candidate/CandidateEventUtils';
import eventTypes from '../../Analytics/EventTypes';
import DripConfigSwitch from '../Connect/DripConfigSwitch/DripConfigSwitch';
import UnsubscribeCandidateButton from '../UnsubscribeCandidate/UnsubsCribeCandidateButton';
import UnsubscribedButton from '../UnsubscribeCandidate/UnsubscribedButton';
import placeholder from '../Placeholders/PlaceholdersMessages';
import * as ConnectActions from '../../Actions/ConnectActions';

const mapStateToProps = (state, props) => {
  return {
    phoneNumbers: getAllPhoneNumbersOfCandidate(state, props.candidate.PersonId),
  };
};

const mapDispatchToProps = {
  fetchOrgPhoneConsentInfo: ConnectActions.fetchOrgPhoneConsentInfo,
};

function CandidateContactsWrapper(props) {
  const {
    candidate,
    jobGuid,
    jobId,
    currentJobDetails,
    jobCountryCode,
    toggleBotConfigDrawer,
    candidateContext,
    activeSubTab,
    onSubTabClick,
    isCandidateViewHeaderVisible,
    setCandidateViewHeaderVisibility,
    version,
    creditsRefunded,
    openSipCallWindowsApp,
    isContactTabRedirection,
    isAlertMessage,
    isConnectIconsEnabled,
    isAudioReadVisible,
    isMessageReadVisible,
    isEmailReadVisible,
    composeEmailType,
    setComposeEmailType,
    featureToggleList,
    isCandidateUnsubscribed,
    phoneNumbers,
    fetchOrgPhoneConsentInfo,
  } = props;

  const ref = React.useRef(null);
  const mailRef = React.useRef(null);
  const smsRef = React.useRef(null);
  const callRef = React.useRef(null);

  const dripStats = useSelector(state => getDripStats(state));
  const dripConfigStatus = useSelector(state => getApiStatus(state, 'dripConfigApiStatus'));
  const [isDripActive, setIsDripActive] = useState(false);
  const [isComposeEmailModalVisible, setEmailComposeModalVisibility] = React.useState(false);
  const [isComposeMessageModalVisible, setMessageComposeModalVisibility] = React.useState(false);
  const [isComposeWorkflowModalVisible, setWorkflowComposeModalVisibility] = React.useState(false);
  const [currentSelectedEmail, setCurrentSelectedEmail] = React.useState(null);
  const [workflowSelectedEmails, setWorkflowSelectedEmails] = React.useState([]);
  const [phoneNumber, setPhoneNumber] = React.useState(null);

  const conversationDripStats = dripStats?.[candidate?.ConversationId];
  const scheduledEmailsCount = conversationDripStats?.reduce((totalScheduledDripCount, currentTemplateDripStats) => {
    return totalScheduledDripCount + currentTemplateDripStats.ScheduledEmailsCount;
  }, 0);

  React.useEffect(() => {
    setIsDripActive(scheduledEmailsCount > 0);
    if (dripConfigStatus === 'FAILED') {
      setIsDripActive(currentDripStatus => !currentDripStatus);
    }
    const onScroll = () => {
      if (ref.current.scrollTop === 0) setCandidateViewHeaderVisibility(true);
      else setCandidateViewHeaderVisibility(false);
    };
    ref.current.addEventListener('scroll', onScroll);
    return () => {
      ref.current.removeEventListener('scroll', onScroll);
    };
  }, [scheduledEmailsCount, dripConfigStatus, []]);

  React.useEffect(() => {
    if (candidate?.PersonId) fetchOrgPhoneConsentInfo(phoneNumbers, candidate.PersonId);
  }, []);
  const contextualEmailCount = mailRef.current?.state.allEmails?.length || 0;
  const contextualMessageCount = smsRef.current?.state.messages?.length || 0;
  const isMailCommunicationAllowed = mailRef.current?.state?.isCommunicationAllowed;
  const isEmailTabActive = activeSubTab === 'email';
  const isEmailComposeButtonVisible = isEmailTabActive && contextualEmailCount && isMailCommunicationAllowed;
  const isMessageCommunicationAllowed = smsRef.current?.state?.isCommunicationAllowed;
  const isMessageComposeButtonVisible =
    activeSubTab === 'message' && contextualMessageCount && isMessageCommunicationAllowed;
  const isComposeButtonVisible = isEmailComposeButtonVisible || isMessageComposeButtonVisible;

  let setComposeModalVisibility = null;
  if (activeSubTab === 'email') setComposeModalVisibility = setEmailComposeModalVisibility;
  else if (activeSubTab === 'workflow') setComposeModalVisibility = setWorkflowComposeModalVisibility;
  else setComposeModalVisibility = setMessageComposeModalVisibility;
  const contactsWrapperStyle = isAlertMessage ? { height: 'calc(100vh - 200px)' } : {};
  const consentInforBanner = mailRef.current?.getConsentStatusInfoBanner();
  const { setDefaultEmailTemplateValues } = mailRef.current || {};
  const { setCurrentReplyingToEmail } = mailRef.current || {};
  const { setSubjectDisability } = mailRef.current || {};
  const composeEventName = isEmailTabActive
    ? eventTypes.candidate.candidateConnect.composeMail
    : eventTypes.candidate.candidateConnect.composeSms;
  const selectedFromEmail = mailRef.current?.getCurrentSelectedFromEmail() || {};
  const { selectedEmail } = mailRef.current?.state || {};
  const isUnsubscribeCandidateEnabled = featureToggleList.UnsubscribeCandidate.IsEnabled;
  const unsubscribeCandidateButton = !isCandidateUnsubscribed ? (
    <UnsubscribeCandidateButton candidate={candidate} />
  ) : (
    <UnsubscribedButton candidate={candidate} />
  );

  return (
    <div className={styles.candidateContactsWrapper}>
      <div
        className={isCandidateViewHeaderVisible ? styles.contacts : styles.extendView}
        style={contactsWrapperStyle}
        ref={ref}
      >
        <div className={styles.candidateContactInfo}>
          <ContactInformationContainer
            candidate={candidate}
            jobGuid={jobGuid}
            creditsRefunded={creditsRefunded}
            currentJobDetails={currentJobDetails}
            mailRef={mailRef}
            smsRef={smsRef}
            callRef={callRef}
            onSubTabClick={onSubTabClick}
            setEmailComposeModalVisibility={setEmailComposeModalVisibility}
            setMessageComposeModalVisibility={setMessageComposeModalVisibility}
            setWorkflowComposeModalVisibility={setWorkflowComposeModalVisibility}
            setCurrentSelectedEmail={setCurrentSelectedEmail}
            setWorkflowSelectedEmails={setWorkflowSelectedEmails}
            featureToggleList={featureToggleList}
          />
          <CandidateProfileContactsContainer
            candidate={candidate}
            jobId={jobId}
            currentJobDetails={currentJobDetails}
            jobCountryCode={jobCountryCode}
            toggleBotConfigDrawer={toggleBotConfigDrawer}
            candidateContext={candidateContext}
            activeSubTab={activeSubTab}
            onSubTabClick={onSubTabClick}
            version={version}
            mailRef={mailRef}
            smsRef={smsRef}
            callRef={callRef}
            isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
            isComposeEmailModalVisible={isComposeEmailModalVisible}
            isComposeWorkflowModalVisible={isComposeWorkflowModalVisible}
            setEmailComposeModalVisibility={setEmailComposeModalVisibility}
            setWorkflowComposeModalVisibility={setWorkflowComposeModalVisibility}
            isComposeMessageModalVisible={isComposeMessageModalVisible}
            setMessageComposeModalVisibility={setMessageComposeModalVisibility}
            currentSelectedEmail={currentSelectedEmail}
            workflowSelectedEmails={workflowSelectedEmails}
            setWorkflowSelectedEmails={setWorkflowSelectedEmails}
            setCurrentSelectedEmail={setCurrentSelectedEmail}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            isMailCommunicationAllowed={isMailCommunicationAllowed}
            isMessageCommunicationAllowed={isMessageCommunicationAllowed}
            openSipCallWindowsApp={openSipCallWindowsApp}
            isContactTabRedirection={isContactTabRedirection}
            setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
            isConnectIconsEnabled={isConnectIconsEnabled}
            isEmailReadVisible={isEmailReadVisible}
            isAudioReadVisible={isAudioReadVisible}
            isMessageReadVisible={isMessageReadVisible}
            composeEmailType={composeEmailType}
            setComposeEmailType={setComposeEmailType}
            contactsTabRef={ref}
            isUnsubscribeCandidateEnabled={isUnsubscribeCandidateEnabled}
            unsubscribeCandidateButton={unsubscribeCandidateButton}
          />
        </div>
      </div>
      {isComposeButtonVisible ? (
        <Affix offsetBottom={0} className={styles.composeButtonWrapper}>
          <div className={styles.composeButton}>
            {isDripActive && isEmailTabActive ? (
              <div className={styles.disclaimerMessage}>
                <div>
                  <InfoCircleIcon />
                </div>
                <b>
                  <u>
                    <FormattedMessage {...placeholder.noteLabel} />:
                  </u>
                </b>{' '}
                <FormattedMessage {...placeholder.workFlowWillStopOnceCandidateResponds} />
              </div>
            ) : null}
            {isUnsubscribeCandidateEnabled ? unsubscribeCandidateButton : null}
            <div className={styles.dripAndComposeButtonWrapper}>
              {isDripActive && isEmailTabActive ? (
                <DripConfigSwitch
                  dripConfigStatus={dripConfigStatus}
                  conversationId={candidate.ConversationId}
                  selectedFromEmail={selectedFromEmail}
                  isVisible={!!selectedEmail?.EmailAddress && !featureToggleList.PaidJobService.IsEnabled}
                  candidate={candidate}
                  isDripActive={isDripActive}
                />
              ) : null}
              <ComposeEmailAndMessageButton
                setComposeModalVisibility={setComposeModalVisibility}
                activeTab={activeSubTab}
                composeEmailType={composeEmailType}
                setComposeEmailType={setComposeEmailType}
                isDisabled={(isEmailTabActive && !!consentInforBanner) || isCandidateUnsubscribed}
                setDefaultEmailTemplateValues={setDefaultEmailTemplateValues}
                setCurrentReplyingToEmail={setCurrentReplyingToEmail}
                setSubjectDisability={setSubjectDisability}
                shouldChangeMailValues
                skEventName={getEventNameByCandidateStatus(composeEventName, candidate)}
              />
            </div>
          </div>
        </Affix>
      ) : null}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateContactsWrapper);
export { CandidateContactsWrapper as CandidateContactsWrapperWithoutStore };
